import { useState } from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import { LiaChevronCircleDownSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
function TicketListDetail() {
  const [activeKeys, setActiveKeys] = useState(["0", "1", "2", "3"]);

  const toggleAccordion = (key: string) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((k) => k !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  const options = [
    { name: "Option 1", id: 1 },
    { name: "Option 2", id: 2 },
    { name: "Option 3", id: 3 },
  ];
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pageTitle">
              <h1>Billing Issues</h1>
            </div>
          </div>
          <div className="col-12">
            <div className="display-card issueWrap mt-4">
              <Accordion activeKey={activeKeys}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header onClick={() => toggleAccordion("1")}>
                    <span className="brand-color me-2">
                      <LiaChevronCircleDownSolid />
                    </span>{" "}
                    Comments
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row form-group">
                      <div className="col-lg-3 mb-3">
                        <label>Complain Subject</label>
                        <p>Issue</p>
                      </div>
                      <div className="col-lg-9 mb-3">
                        <label>Description</label>
                        <p>Others</p>
                      </div>
                    </div>
                    <div className="commentWrap d-flex">
                      <span className="textAvatar me-3">A</span>
                      <div className="w-100">
                        <textarea
                          name=""
                          className="univ-input"
                          placeholder="Type comments here..."
                          id=""
                        ></textarea>
                        <div className="text-end mt-2">
                          <button className="univ-btn">Post Comment</button>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div className="form-group row">
                      <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
                        <label>Moderators</label>
                        <Multiselect
                          options={options}
                          displayValue="name"
                          placeholder="Select Moderators"
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
                        <label>Priority</label>
                        <select name="" className="univ-input" id="">
                          <option value="">Select Priority</option>
                          <option value="">High</option>
                          <option value="">Medium</option>
                          <option value="">Low</option>
                        </select>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                  <Accordion.Header onClick={() => toggleAccordion("0")}>
                    <span className="brand-color me-2">
                      <LiaChevronCircleDownSolid />
                    </span>{" "}
                    Details
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="form-group row">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Category</label>
                        <p>Others</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Type</label>
                        <p>Member</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Domain</label>
                        <Link to="" className="brand-color">
                          tickets.purenroll.com
                        </Link>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Browser</label>
                        <p>Google Crome</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Status</label>
                        <div className="mt-status">
                          <Dropdown drop="down-centered" className="dropdown">
                            <Dropdown.Toggle>
                              <span>Open</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <span>Pending</span>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <span>Resolved</span>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <span>Open</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Opend Date</label>
                        <p>10/06/2024</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Closed Date</label>
                        <p>10/06/2024</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Last Login</label>
                        <p>09/09/2021 08:25:22</p>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={() => toggleAccordion("2")}>
                    <span className="brand-color me-2">
                      <LiaChevronCircleDownSolid />
                    </span>{" "}
                    Issue Documents
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="attachmentList">
                      <ul>
                        <li>
                          <span className="textAvatar">F</span>
                          <div>
                            <h4>file_001.zip</h4>
                            <p>3.26MB</p>
                          </div>
                        </li>
                        <li>
                          <span className="textAvatar">F</span>
                          <div>
                            <h4>file_001.zip</h4>
                            <p>3.26MB</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header onClick={() => toggleAccordion("3")}>
                    <span className="brand-color me-2">
                      <LiaChevronCircleDownSolid />
                    </span>{" "}
                    Submitter Details
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="form-group row">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Submitter</label>
                        <p>Sheldon Man</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Email</label>
                        <p>testrepcts@gmail.com</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Phone</label>
                        <p>N/A</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>User Type</label>
                        <p>Agent Group</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>System Ids</label>
                        <p>3253, a100955</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Device</label>
                        <p>web</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Agent Ids</label>
                        <p>100955</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Group Ids</label>
                        <p>3253</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Register</label>
                        <p>Yes</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Registered Date</label>
                        <p>10/27/2024</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Last Login IP</label>
                        <p>24.63.144.167</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Last Login Date</label>
                        <p>10/27/2024</p>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>Mobile Application </label>
                        <p>No</p>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TicketListDetail;
