import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
function Pagination(){
    return(
        <>
        <div className="px-3 pb-2">
        <div className="row align-items-center">
            <div className="col-lg-6">
            <p className="mb-0">1-10 of 97</p>
            </div>
            <div className="col-lg-6">
                <div className="text-end d-flex align-items-center justify-content-end">
                    Rows per page: 
                    <select className="univ-btn-transparent" name="" id="">
                        <option value="">10</option>
                        <option value="">5</option>
                        <option value="">10</option>
                        <option value="">15</option>
                        <option value="">20</option>
                    </select>
                    <div>
                        <MdOutlineChevronLeft className="pe-cursor" />
                        <span>1/10</span>
                        <MdOutlineChevronRight className="pe-cursor" />
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    )
}
export default Pagination;