const Loader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "60vh" }}
    >
      <div className="spinner-border text-primary" role="status"></div>
    </div>
  );
};

export default Loader;
