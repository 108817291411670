import SubmittersList from "./partials/SubmittersList";

function TicketSubmitters() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pageTitle">
              <h1>Ticket Submitters</h1>
            </div>
          </div>
          <div className="col-12">
            <div className="display-card p-0 mt-4">
              <SubmittersList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TicketSubmitters;
