import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';
import { MdSpaceDashboard, MdOutlineFormatListBulleted } from "react-icons/md";
import { LuNetwork } from "react-icons/lu";
import { LiaUsersCogSolid } from "react-icons/lia";
import { IoPaperPlane, IoSettingsSharp, IoLanguage, IoCloseOutline } from "react-icons/io5";
import { FaRobot } from 'react-icons/fa';
import Logo from '../assets/images/logo.svg'

type SidebarMenuProps = {
    isCollapsed: boolean;
    toggleSidebar: () => void;
};

function SidebarMenu({ isCollapsed, toggleSidebar }: SidebarMenuProps){
    return(
        <>
        <Sidebar
            breakPoint="lg"
            collapsedWidth="80px"
            collapsed={isCollapsed}
            backgroundColor="transparent"
        >
            <div onClick={toggleSidebar} className='pe-cursor text-center closeBtn-sidebar pt-3'><IoCloseOutline /></div>
            <div className="sidebarHeader">
                <img src={Logo} className='img-fluid' alt="" />
            </div>
            <Menu>
                <MenuItem icon={<MdSpaceDashboard />} component={<NavLink to="/" />}> Dashboard</MenuItem>
                <MenuItem icon={<MdOutlineFormatListBulleted />} component={<NavLink to="/all-issues" />}> All Issues</MenuItem>
                <MenuItem icon={<LuNetwork />} component={<NavLink to="/category" />}> Category</MenuItem>
                <MenuItem icon={<LiaUsersCogSolid />} component={<NavLink to="/users" />}> User</MenuItem>
                <MenuItem icon={<IoPaperPlane />} component={<NavLink to="/ticket-submitters" />}> Ticket Submitters</MenuItem>
                <MenuItem icon={<IoSettingsSharp />} component={<NavLink to="/manage-tenents" />}> Manage Tenant</MenuItem>
                <MenuItem icon={<FaRobot />} component={<NavLink to="/knowledge-base" />}> CoreyAI Knowledge Base</MenuItem>
                <MenuItem icon={<IoLanguage />} component={<NavLink to="/train-chatbot" />}> Train Your Chatbot <span className='menu-badge'>Beta</span></MenuItem>
            </Menu>
        </Sidebar>
        </>
    )
}
export default SidebarMenu;