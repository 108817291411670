import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { BsFilterLeft } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useGetAllDataQuery } from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";

function IssuesFilter({
  onFilterApply,
  loading,
}: {
  onFilterApply: (filters: any) => void;
  loading: boolean;
}) {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    tenant: "",
    submitter: "",
    moderator: "",
  });

  const { data: tenantsData } = useGetAllDataQuery({
    url: Endpoint.GET_TENANT,
  });

  const { data: submitterData } = useGetAllDataQuery({
    url: Endpoint.GET_SUBMITTER,
  });

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const showFilterHandler = () => {
    setShowFilter(true);
  };

  const hideFilterHandler = () => {
    setShowFilter(false);
  };

  const handleClearFilter = () => {
    setFilters({
      tenant: "",
      submitter: "",
      moderator: "",
    });
    onFilterApply({});
  };

  const handleApplyFilter = () => {
    onFilterApply(filters);
  };

  return (
    <>
      <div className="tableFilter p-3">
        <div className="searchWrap">
          <div className="searchField position-relative">
            <input type="text" name="" id="" placeholder="Enter Keyword" />
            <button className="px-4">Search</button>
            <IoSearchOutline />
          </div>
          <button
            className="univ-btn-secondary ms-2"
            onClick={showFilterHandler}
          >
            <BsFilterLeft /> Filter
          </button>
        </div>
        {showFilter && (
          <>
            <div className="row mt-4">
              <div className="ol-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <select
                  name="tenant"
                  className="univ-input"
                  value={filters.tenant}
                  onChange={handleFilterChange}
                >
                  <option value="">Select Associated Tenant</option>
                  {tenantsData?.data?.data?.map((tenant: any) => (
                    <option key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <select
                  name="submitter"
                  className="univ-input"
                  id=""
                  value={filters.submitter}
                  onChange={handleFilterChange}
                >
                  <option value="">Submitter Name</option>
                  {submitterData?.data?.data?.map((submitter: any) => (
                    <option key={submitter.id} value={submitter?.first_name}>
                      {submitter?.first_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <select
                  name="moderator"
                  className="univ-input"
                  id=""
                  value={filters.moderator}
                  onChange={handleFilterChange}
                >
                  <option value="">Assigned Moderator</option>
                  <option value="name1">Name 1</option>
                  <option value="name2">Name 2</option>
                  <option value="name3">Name 3</option>
                </select>
              </div>
              <div className="col-12">
                <div className="searchWrap justify-content-end">
                  <button
                    className="univ-btn-transparent text-danger me-3"
                    onClick={handleClearFilter}
                  >
                    <MdDeleteOutline /> Clear Filter Data
                  </button>
                  <div className="d-flex">
                    <button
                      className="univ-btn-transparent"
                      onClick={hideFilterHandler}
                    >
                      Cancel
                    </button>
                    <button
                      className="univ-btn me-3"
                      onClick={handleApplyFilter}
                    >
                      {loading ? "Applying...." : " Apply Filter"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        )}
      </div>
    </>
  );
}
export default IssuesFilter;
