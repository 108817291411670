import { BsTicket } from "react-icons/bs";
import {
  IoBookOutline,
  IoEllipsisHorizontalCircle,
  IoTicketOutline,
} from "react-icons/io5";
import Endpoint from "../../../services/Endpoints";
import { useGetAllDataQuery } from "../../../services/Api";

function TotalCount() {
  const { data: counts } = useGetAllDataQuery(
    {
      url: Endpoint.GET_STATS,
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <>
      <div className="col-xl-3 mt-3">
        <div className="display-card totaCounts px-3">
          <p>Total Tickets</p>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="ts-icon">
              <BsTicket />
            </div>
            <h3>{counts?.data?.total_issues || 0}</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <span>Total Clients</span>
            <span>{counts?.data?.total_clients || 0}</span>
          </div>
        </div>
      </div>
      <div className="col-xl-3 mt-3">
        <div className="display-card totaCounts openTicket px-3">
          <p>Open Tickets</p>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="ts-icon">
              <IoBookOutline />
            </div>
            <h3>{counts?.data?.active_issues || 0}</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <span>Total Clients</span>
            <span>{counts?.data?.total_clients || 0}</span>
          </div>
        </div>
      </div>
      <div className="col-xl-3 mt-3">
        <div className="display-card totaCounts resolvedTicket px-3">
          <p>Resolved Tickets</p>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="ts-icon">
              <IoTicketOutline />
            </div>
            <h3>{counts?.data?.solved_issues || 0}</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <span>Total Clients</span>
            <span>{counts?.data?.total_clients || 0}</span>
          </div>
        </div>
      </div>
      <div className="col-xl-3 mt-3">
        <div className="display-card totaCounts pendingTickets px-3">
          <p>Pending Tickets</p>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="ts-icon">
              <IoEllipsisHorizontalCircle />
            </div>
            <h3>{counts?.data?.pending_issues || 0}</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <span>Total Clients</span>
            <span>{counts?.data?.total_clients || 0}</span>
          </div>
        </div>
      </div>
    </>
  );
}
export default TotalCount;
