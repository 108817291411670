import { useState } from "react";
import { IoAddCircleSharp } from "react-icons/io5";
import CredentialList from "./partials/CredentialList";
import CredentialFilter from "./partials/CredentialFilter";
import AddDatabaseConfig from "./Modals/AddDatabaseConfig";
function DatabaseCredential() {
    const [showModal, setShowModal] = useState(false);
  
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);  
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="pageTitle">
                            <h1>Database Credential</h1>
                            <button className="univ-btn" onClick={handleShow}>
                                <IoAddCircleSharp /> New Database Credential
                            </button>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="display-card mt-3 p-0">
                            <CredentialFilter />
                            <CredentialList />
                        </div>
                    </div>
                </div>
            </div>

            <AddDatabaseConfig show={showModal} onHide={handleClose} />
        </>
    )
}
export default DatabaseCredential;