import { useState } from "react";
import { IoAddCircleSharp } from "react-icons/io5";
import SiteConfigList from "./partials/SiteConfigList";
import AddSiteConfig from "./Modals/AddSiteConfig";
function SiteConfig() {
    const [showModal, setShowModal] = useState(false);
  
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);  
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="pageTitle">
                            <h1>Site Configuration</h1>
                            <button className="univ-btn" onClick={handleShow}>
                                <IoAddCircleSharp /> New Site Configuration
                            </button>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="display-card mt-3 p-0">
                            <SiteConfigList />
                        </div>
                    </div>
                </div>
            </div>
            <AddSiteConfig show={showModal} onHide={handleClose} />
        </>
    )
}
export default SiteConfig;