import Modal from "react-bootstrap/Modal";
import { MdDeleteOutline } from "react-icons/md";

interface DeleteDialogProps {
  show: boolean;
  onDialogHide: () => void;
  onDelete: () => void;
  title: string;
  message: string;
  loading: boolean;
}

function DeleteDialog({
  show,
  onDialogHide,
  onDelete,
  title,
  message,
  loading,
}: DeleteDialogProps) {
  return (
    <>
      <Modal show={show} onHide={onDialogHide} centered>
        <Modal.Body>
          <div className="qs-dg-blck">
            <div className="qs-icon danger-icon me-2">
              <MdDeleteOutline />
            </div>
            <div>
              <h3>{title}</h3>
              <p>{message}</p>
            </div>
          </div>
          <div className="text-end">
            <span className="pe-cursor" onClick={onDialogHide}>
              Cancel
            </span>
            <button
              className="ms-3 univ-btn danger-bg border-0"
              onClick={onDelete}
            >
              {loading ? "Deleting...." : "Delete"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteDialog;
