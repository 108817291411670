import DashboardOverview from "./partials/DashboardOverview";
import TotalCount from "./partials/TotalCount";

function Dashboard(){
    return(
        <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="pageTitle">
                        <h1>Welcome to Ticketing System</h1>
                    </div>
                </div>
                <TotalCount />
                <DashboardOverview />
            </div>
        </div>
        </>
    )
}
export default Dashboard;