import Modal from 'react-bootstrap/Modal';
import { IoClose, IoFlagOutline } from "react-icons/io5";
import DefaultAvatar from '../../../assets/images/default-avatar.svg'

interface ViewTenetsProps {
    show: boolean;
    onViewHide: () => void;
}
function ViewTenants({ show, onViewHide }: ViewTenetsProps){
    return(
        <>
        <Modal show={show} onHide={onViewHide} centered size='lg' >
            <Modal.Body>
                <div className="modal-head">
                    <div>
                        <button className='univ-btn-border mb-2'><IoFlagOutline/></button>  
                        <h2>Tenent Details</h2>
                        <p>Here are the related informations of tenent.</p>
                    </div>
                    <button className='univ-btn-transparent p-0' onClick={onViewHide}><IoClose /></button>
                </div>
                <div>
                    <div className="form-group row mb-3">
                        <div className="col-lg-4 mb-3">
                            <label>Name</label>
                            <p>Option Plus</p>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <label>Domain Name</label>
                            <span className='brand-color'>www.domainname.com</span>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <label>Tenent Logo</label>
                            <div className="d-flex align-items-center">
                                <div className="logoThumb me-3">
                                    <img src={DefaultAvatar} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="themeList">
                                <label>Color Theme</label>
                                <ul>
                                    <li>
                                        <span className='themeBlock pe-cursor'></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-lg-3 mb-3">
                            <button className='univ-btn-secondary w-100'>Edit Details</button>
                        </div>
                    </div>
                    {/* <div className="form-group row mt-3">
                        <div className="col-sm-6 mb-3">
                            <button className='univ-btn-border w-100'>Cancel</button>
                        </div>
                        <div className="col-sm-6 mb-3">
                            <button className='univ-btn w-100'>Edit Details</button>
                        </div>
                    </div> */}
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
}
export default ViewTenants;