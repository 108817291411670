import { useState } from "react";
import { IoAddCircleSharp } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";
import { Tab, Tabs } from "react-bootstrap";
import KnowledgeOverview from "./partials/KnowledgeOverview";
import ChatSessios from "./partials/ChatSessions";
import ChatFlow from "./partials/ChatFlow";
import AddQuesModal from "./modal/AddQuesModal";
import UploadFile from "./modal/UploadFile";

function KnowledgeBase() {
  const [showModal, setShowModal] = useState(false);
  const [refetch, setRefetch] = useState<() => void>(() => {});

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleUploadShow = () => setShowUploadModal(true);
  const handleUploadClose = () => setShowUploadModal(false);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pageTitle">
              <h1>CoreyAI Knowledge Base</h1>
              <div>
                <button
                  className="univ-btn-secondary me-3"
                  onClick={handleUploadShow}
                >
                  <FaFileAlt /> Upload File
                </button>
                <button className="univ-btn" onClick={handleShow}>
                  <IoAddCircleSharp /> New Question & Answer
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <Tabs
              defaultActiveKey="overview"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="overview" title="Overview">
                <KnowledgeOverview />
                <ChatSessios />
              </Tab>
              <Tab eventKey="chartflow" title="Chartflow">
                <ChatFlow setRefetch={setRefetch} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <AddQuesModal show={showModal} onHide={handleClose} refetch={refetch} />
      <UploadFile show={showUploadModal} onHideUpload={handleUploadClose} />
    </>
  );
}
export default KnowledgeBase;
