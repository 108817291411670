import Modal from "react-bootstrap/Modal";
import { IoClose, IoFlagOutline } from "react-icons/io5";

interface ViewQuesProps {
  show: boolean;
  onHideView: () => void;
  question: any;
}
function ViewQuesModal({ show, onHideView, question }: ViewQuesProps) {
  return (
    <>
      <Modal show={show} onHide={onHideView} centered size="lg">
        <Modal.Body>
          <div className="modal-head">
            <div className="d-flex">
              <button className="univ-btn-border mb-2 me-3">
                <IoFlagOutline />
              </button>
              <div>
                <h2>View Questions and Answers</h2>
                <p>Detail view of Question Answer</p>
              </div>
            </div>
            <div>
              <button className="univ-btn-transparent p-0" onClick={onHideView}>
                <IoClose />
              </button>
            </div>
          </div>
          <div className="qna-wrap my-3">
            <div className="form-group mb-3">
              <label>Question</label>
              <p>{question?.query}</p>
            </div>
            <div className="form-group mb-3">
              <label>Answer</label>
              <p>{question?.answer}</p>
            </div>
            <div className="form-group mb-2">
              <label>Status</label>
              <p>{question?.status === 1 ? "Active" : "Inactive"}</p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-6 mt-3">
              <button className="univ-btn-border w-100" onClick={onHideView}>
                Cancel
              </button>
            </div>
            <div className="col-sm-6 mt-3">
              <button className="univ-btn w-100">Done</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ViewQuesModal;
