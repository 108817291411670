import { Tab, Tabs } from 'react-bootstrap';
import Overview from './Overview';
import TicketList from './TicketList';
function DashboardOverview() {
    return (
        <>
            <section className="mt-4">
                <div className="sectionTitle">
                    <h2>Overview</h2>
                </div>
                <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="overview" title="Overview">
                        <Overview />
                    </Tab>
                    <Tab eventKey="allTickets" title="All Tickets">
                        <TicketList />
                    </Tab>
                    <Tab eventKey="openTickets" title="Open Tickets">
                        Tab content for Contact
                    </Tab>
                    <Tab eventKey="resolvedTickets" title="Resolved Tickets">
                        Tab content for Contact
                    </Tab>
                    <Tab eventKey="pendingTickets" title="Pending Tickets">
                        Tab content for Contact
                    </Tab>
                </Tabs>
            </section>
        </>
    )
}
export default DashboardOverview;