import { MdEdit, MdDeleteOutline } from "react-icons/md";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
function CredentialList() {
    return (
        <>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Connection</th>
                            <th>Host</th>
                            <th>Port</th>
                            <th>Database Name</th>
                            <th>Username</th>
                            <th>Password</th>
                            <th>Created On</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>mySQL</td>
                            <td>3.12.25.125</td>
                            <td>3360</td>
                            <td>SSOAPP</td>
                            <td>easynroll</td>
                            <td>asdf235r253</td>
                            <td>10/02/2024</td>
                            <td>
                                <div className="tableAction">
                                    <ul>
                                        <li>
                                            <button
                                                className="univ-btn"
                                            >
                                                <MdEdit /> Edit
                                            </button>
                                        </li>
                                        <li>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Delete</Tooltip>}
                                            >
                                                <button
                                                    className="univ-btn-border text-danger"
                                                >
                                                    <MdDeleteOutline />
                                                </button>
                                            </OverlayTrigger>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>mySQL</td>
                            <td>3.12.25.125</td>
                            <td>3360</td>
                            <td>SSOAPP</td>
                            <td>easynroll</td>
                            <td>asdf235r253</td>
                            <td>10/02/2024</td>
                            <td>
                                <div className="tableAction">
                                    <ul>
                                        <li>
                                            <button
                                                className="univ-btn"
                                            >
                                                <MdEdit /> Edit
                                            </button>
                                        </li>
                                        <li>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Delete</Tooltip>}
                                            >
                                                <button
                                                    className="univ-btn-border text-danger"
                                                >
                                                    <MdDeleteOutline />
                                                </button>
                                            </OverlayTrigger>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default CredentialList;