const handleErrors = (response: any, setErrorCallback: any) => {
  if (response.error?.data?.errors) {
    const errorObject: any = {};
    for (const field in response.error.data.errors) {
      if (
        Object.prototype.hasOwnProperty.call(response.error.data.errors, field)
      ) {
        errorObject[field] = response.error.data.errors[field].join(", ");
      }
    }
    setErrorCallback(errorObject);
  }
};

export default handleErrors;
