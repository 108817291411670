type EndpointType = {
  //user
  GET_USER: string;
  CREATE_USER: string;
  UPDATE_USER: string;
  //category
  GET_CATEGORY: string;
  CREATE_CATEGORY: string;
  UPDATE_CATEGORY: string;
  //issue
  GET_ISSUE: string;
  //comment
  CREATE_COMMENT: string;
  //submitter
  GET_SUBMITTER: string;
  //tenant
  GET_TENANT: string;
  CREATE_TENANT: string;
  //moderator
  POST_MODERATOR: string;
  //question&answer
  GET_QUESTION_ANSWER: string;
  DELETE_QUESTION_ANSWER: string;
  CREATE_QUESTION_ANSWER: string;
  //dashboard
  GET_STATS: string;
};

const Endpoint: EndpointType = {
  //user
  GET_USER: "superadmin/users",
  CREATE_USER: "superadmin/users/create",
  UPDATE_USER: "superadmin/users/update",
  //category
  GET_CATEGORY: "superadmin/categories",
  CREATE_CATEGORY: "superadmin/categories/create",
  UPDATE_CATEGORY: "superadmin/categories/update",
  //tenant
  GET_TENANT: "tenant",
  CREATE_TENANT: "tenant",
  //issue
  GET_ISSUE: "superadmin/issues",
  //comment
  CREATE_COMMENT: "issue-comment/comment",
  //submitter
  GET_SUBMITTER: "superadmin/submitters",
  //moderator
  POST_MODERATOR: "superadmin/users/assign-moderator",
  //question&answer
  GET_QUESTION_ANSWER: "/superadmin/knowledge",
  DELETE_QUESTION_ANSWER: "superadmin/knowledge/delete",
  CREATE_QUESTION_ANSWER: "superadmin/knowledge/",
  //dashboard
  GET_STATS: "dashboard/stats",
};

export default Endpoint;
