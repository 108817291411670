import { FaArrowUp } from "react-icons/fa6";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Dropdown, ProgressBar } from 'react-bootstrap';
function Overview(){
    return(
        <>
        <div className="row">
            <div className="col-xl-3 mb-3">
                <div className="display-card p-0">
                    <div className="card-title p-3">
                        <h3>Top Moderators</h3>
                    </div>
                    <div className="totalCount px-3">
                        <label>Total</label>
                        <figure>20 <span className="countUp">5 <FaArrowUp /> This week</span></figure>
                    </div>
                    <div className="modList">
                        <PerfectScrollbar style={{ maxHeight: "230px", minHeight: "230px" }}>
                            <ul>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            K
                                        </div>
                                        <div>
                                            <h4>Kristin Camper</h4>
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>92%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            J
                                        </div>
                                        <div>
                                            <h4>Jenny Sitaula</h4>
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>90%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            R
                                        </div>
                                        <div>
                                            <h4>Rooney Paukl</h4>
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>82%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            K
                                        </div>
                                        <div>
                                            <h4>Kristin Camper</h4>
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>92%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            J
                                        </div>
                                        <div>
                                            <h4>Jenny Sitaula</h4>
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>90%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            R
                                        </div>
                                        <div>
                                            <h4>Rooney Paukl</h4>
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>82%</span>
                                </li>
                            </ul>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
            <div className="col-xl-9 mb-3">
                <div className="display-card performanceCard px-4">
                    <div className="card-title mb-3">
                        <div className="d-flex align-items-center">
                            <h3 className="mb-0 me-2">Performance by</h3>
                            <div className="userSelector">
                                    <Dropdown
                                        drop="down-centered"
                                        className="dropdown"
                                    >
                                        <Dropdown.Toggle>
                                        Jenny
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                            >
                                                <span>
                                                Phoenix Baker
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                            >
                                                <span>
                                                Lana Steiner
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                            >
                                                <span>
                                                Demi Wilkinson
                                                </span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                            </div>
                        </div>
                    </div>
                    <ul>
                        <li>
                            Tenent : <span>Life Plus</span>
                        </li>
                        <li>
                            Domain: <span className="brand-color">www.domain.com/url</span>
                        </li>
                    </ul>
                    <hr />
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="ticketValue valueSeparator text-center">
                                <label className="mb-2">Assigned <br /> Tickets</label>
                                <h4>100</h4>
                                <span>VS</span>
                                <div className="indicator assignedIndicator"></div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="ticketValue valueSeparator text-center">
                                <label className="mb-2">Solved <br /> Tickets</label>
                                <h4>90</h4>
                                <span>VS</span>
                                <div className="indicator solvedIndicator"></div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="ticketValue text-center">
                                <label className="mb-2">Time <br /> Taken</label>
                                <h4>09:30 hrs</h4>
                                <div className="indicator timeIndicator"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-9 mb-3">
                <div className="display-card categCard px-4">
                    <div className="card-title mb-3">
                        <h3 className="mb-0 me-2">Category wise Ticket Created</h3>
                    </div>
                    <ul>
                        <li>
                            <div className="d-flex">
                                <label>Accounts</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <label>Billing</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                            </div>
                        </li>
                        <li>
                           <div className="d-flex">
                                <label>Login Issue</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                           </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <label>Registration Issue</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <label>Payment Issue</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <label>Commission Issue</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <label>ID Card Issue</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <label>Logout Issue</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex  ">
                                <label>Bills and Exp</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <label>Card Issue</label>
                                <div className="d-flex align-items-center w-100">
                                    <ProgressBar now={60} />
                                    <span>4125</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-3 mb-3">
                <div className="display-card p-0">
                    <div className="card-title p-3">
                        <h3>Recent Ticket Submitters</h3>
                    </div>
                    <div className="modList">
                        <PerfectScrollbar style={{ maxHeight: "310px", minHeight: "310px" }}>
                            <ul>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            L
                                        </div>
                                        <div>
                                            <h4>Lisa Serchan</h4>
                                            <span>Active: 5</span> | <span>Total: 32</span> <br />
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>92%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            J
                                        </div>
                                        <div>
                                            <h4>Jane Cooper</h4>
                                            <span>Active: 5</span> | <span>Total: 32</span> <br />
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>90%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            B
                                        </div>
                                        <div>
                                            <h4>Bramand Maharjan</h4>
                                            <span>Active: 5</span> | <span>Total: 32</span> <br />
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>82%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            R
                                        </div>
                                        <div>
                                            <h4>Rooney Paul</h4>
                                            <span>Active: 5</span> | <span>Total: 32</span> <br />
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>92%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            L
                                        </div>
                                        <div>
                                            <h4>Lisa Serchan</h4>
                                            <span>Active: 5</span> | <span>Total: 32</span> <br />
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>92%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            J
                                        </div>
                                        <div>
                                            <h4>Jane Cooper</h4>
                                            <span>Active: 5</span> | <span>Total: 32</span> <br />
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>90%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            B
                                        </div>
                                        <div>
                                            <h4>Bramand Maharjan</h4>
                                            <span>Active: 5</span> | <span>Total: 32</span> <br />
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>82%</span>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="mt-avatar">
                                            R
                                        </div>
                                        <div>
                                            <h4>Rooney Paul</h4>
                                            <span>Active: 5</span> | <span>Total: 32</span> <br />
                                            <span>Tenant: </span>
                                        </div>
                                    </div>
                                    <span>92%</span>
                                </li>
                            </ul>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Overview;