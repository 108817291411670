import { MdRemoveRedEye } from "react-icons/md";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useGetAllDataQuery } from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import SubmitterFilter from "./SubmittersFilter";
import Loader from "../../../utils/Loader";
function SubmittersList() {
  const [filters, setFilters] = useState({
    name: "",
    domain: "",
    status: "",
    phone: "",
    email: "",
    role: "",
  });

  const {
    data: submitter,
    isFetching,
    isLoading,
  } = useGetAllDataQuery(
    {
      url: Endpoint.GET_SUBMITTER,
      params: {
        ...filters,
      },
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleFilterApply = (newFilters: any) => {
    setFilters(newFilters);
  };
  return (
    <>
      <SubmitterFilter onFilterApply={handleFilterApply} loading={isLoading} />
      {isFetching ? (
        <Loader />
      ) : (
        <>
          {" "}
          <div className="table-responsive">
            <table className="table striped-bg">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Domain</th>
                  <th>No of Tickets</th>
                  <th>Phone Email</th>
                  <th>Last Submitted Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {submitter?.data?.data?.map((sub: any, index: number) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <label className="heading-color">{sub?.first_name}</label>
                    </td>
                    <td>
                      <span>{sub?.domain}</span>
                    </td>
                    <td>{sub?.no_of_tickets}</td>
                    <td>
                      {sub?.phone} <br /> {sub?.email}
                    </td>
                    <td>{sub?.created_at}</td>
                    <td>
                      <div className="tableAction">
                        <ul>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>View</Tooltip>}
                            >
                              <Link
                                to="/ticket-lists"
                                className="univ-btn-border brand-color"
                              >
                                <MdRemoveRedEye />
                              </Link>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
export default SubmittersList;
