import { useState } from "react";
import { IoAddCircleSharp } from "react-icons/io5";
import TenentList from "./partials/TenentList";
import AddEditTenents from "./Modals/AddEditTenets";
function ManageTenents() {
  const [showModal, setShowModal] = useState(false);
  const [refetch, setRefetch] = useState<() => void>(() => {});

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);  
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pageTitle">
              <h1>Manage Tenents</h1>
              <button className="univ-btn" onClick={handleShow}>
                <IoAddCircleSharp /> Add New Tenents
              </button>
            </div>
          </div>
          <div className="col-12">
            <div className="display-card mt-3 p-0">
              <TenentList setRefetch={setRefetch} />
            </div>
          </div>
        </div>
      </div>

      <AddEditTenents show={showModal} onHide={handleClose} refetch={refetch} />
    </>
  );
}
export default ManageTenents;
