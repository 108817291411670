import Modal from "react-bootstrap/Modal";
import { IoClose, IoFlagOutline } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useCreateDataMutation,
  useGetAllDataQuery,
} from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import Loader from "../../../utils/Loader";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast";
import handleErrors from "../../../utils/ApiError";

interface AddEditUserProps {
  show: boolean;
  onHide: () => void;
  refetch?: () => void;
}

function AddEditUser({ show, onHide, refetch }: AddEditUserProps) {
  const {
    data: tenantsData,
    isLoading,
    isFetching,
  } = useGetAllDataQuery({
    url: Endpoint.GET_TENANT,
    params: {},
  });

  const [createData, { isLoading: isCreating }] = useCreateDataMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      status: "",
      role: "",
      tenant_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: Yup.string().required("Phone number is required"),
      status: Yup.string().required("Status is required"),
      role: Yup.string().required("Role is required"),
      tenant_id: Yup.string().required("Tenant is required"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await createData({
          url: Endpoint.CREATE_USER,
          newData: values,
        });
        handleErrors(res, formik.setErrors);
        if (res?.data?.code === 201) {
          showSuccessToast(res?.data?.message);
          if (refetch) {
            refetch();
          }
          formik.resetForm();
          onHide();
        }
      } catch (error) {
        showErrorToast("...error");
      }
    },
  });

  if (isFetching) return <Loader />;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="modal-head">
          <div>
            <button className="univ-btn-border mb-2">
              <IoFlagOutline />
            </button>
            <h2>New User</h2>
            <p>Please enter a name for user.</p>
          </div>
          <button className="univ-btn-transparent p-0" onClick={onHide}>
            <IoClose />
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="form-group mb-3">
            <label>User (Full Name)</label>
            <input
              type="text"
              className="univ-input"
              placeholder="e.g John Doe"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="error">{formik.errors.name}</div>
            )}
          </div>

          <div className="row form-group">
            <div className="col-md-6 mb-3">
              <label>Email</label>
              <input
                type="text"
                className="univ-input"
                placeholder="Email Address"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="error">{formik.errors.email}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label>Phone Number</label>
              <input
                type="text"
                className="univ-input"
                placeholder="Phone Number"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className="error">{formik.errors.phone}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label>Associated Tenant</label>
            <select
              name="tenant_id"
              className="univ-input"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tenant_id}
            >
              <option value="">Select Tenant</option>
              {tenantsData?.data?.data?.map((tenant: any) => (
                <option key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </option>
              ))}
            </select>
            {formik.touched.tenant_id && formik.errors.tenant_id && (
              <div className="error">{formik.errors.tenant_id}</div>
            )}
          </div>

          <div className="row form-group">
            <div className="col-md-6 mb-3">
              <label>Status</label>
              <select
                name="status"
                className="univ-input"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.status}
              >
                <option value="">Set Status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              {formik.touched.status && formik.errors.status && (
                <div className="error">{formik.errors.status}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label>Role</label>
              <select
                name="role"
                className="univ-input"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
              >
                <option value="">Select Role</option>
                <option value="superadmin">SuperAdmin</option>
                <option value="admin">Admin</option>
                <option value="moderator">Moderator</option>
              </select>
              {formik.touched.role && formik.errors.role && (
                <div className="error">{formik.errors.role}</div>
              )}
            </div>
          </div>

          <div className="form-group row mt-3">
            <div className="col-sm-6 mb-3">
              <button
                type="button"
                className="univ-btn-border w-100"
                onClick={onHide}
              >
                Cancel
              </button>
            </div>
            <div className="col-sm-6 mb-3">
              <button
                type="submit"
                className="univ-btn w-100"
                disabled={isLoading || isCreating}
              >
                {isCreating ? "Creating...." : "Confirm"}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEditUser;
