import { MdDeleteOutline } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import ChatSessionsFilter from "./ChatSessionsFilter";
function ChatSessios() {
  return (
    <>
      <div className="sessionWrap mt-2">
        <h3>Recent Chat Sessions</h3>
        <div className="display-card p-0 mt-3">
          <ChatSessionsFilter />
          <div className="table-responsive">
            <table className="table striped-bg">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>IP Address / Browser</th>
                  <th>Total Session</th>
                  <th>Last Used</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <strong>192.168.245</strong> <br />
                    <span>Mac OSx/ SirieOS</span>
                  </td>
                  <td>2</td>
                  <td>
                    <span>10/10/2023</span> <br />
                    <span>05:02:03</span>
                  </td>
                  <td>
                    <div className="tableAction">
                      <ul>
                        <li>
                          <button className="univ-btn">
                            <IoMdEye /> View Session
                          </button>
                        </li>
                        <li>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                          >
                            <button className="univ-btn-border text-danger">
                              <MdDeleteOutline />
                            </button>
                          </OverlayTrigger>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <strong>192.168.245</strong> <br />
                    <span>Mac OSx/ SirieOS</span>
                  </td>
                  <td>2</td>
                  <td>
                    <span>10/10/2023</span> <br />
                    <span>05:02:03</span>
                  </td>
                  <td>
                    <div className="tableAction">
                      <ul>
                        <li>
                          <button className="univ-btn">
                            <IoMdEye /> View Session
                          </button>
                        </li>
                        <li>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                          >
                            <button className="univ-btn-border text-danger">
                              <MdDeleteOutline />
                            </button>
                          </OverlayTrigger>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChatSessios;
