import Modal from 'react-bootstrap/Modal';
import { IoClose, IoFlagOutline } from "react-icons/io5";
import DefaultAvatar from '../../../assets/images/default-avatar.svg'

interface ViewTenetsProps {
    show: boolean;
    onHide: () => void;
}
function AddSiteConfig({ show, onHide }: ViewTenetsProps){
    return(
        <>
        <Modal show={show} onHide={onHide} centered size='xl' >
            <Modal.Body>
                <div className="modal-head">
                    <div>
                        <button className='univ-btn-border mb-2'><IoFlagOutline/></button>  
                        <h2>Site Configuration</h2>
                        <p>Please select related color for tenant.</p>
                    </div>
                    <button className='univ-btn-transparent p-0' onClick={onHide}><IoClose /></button>
                </div>
                <div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group mb-3">
                                <label>Tenent Name</label>
                                <input type="text" name="" placeholder='Enter Tenent Name' className='univ-input' id="" />
                            </div>
                            <div className="form-group mb-3">
                                <label>Primary Color</label>
                                <div className="position-relative">
                                    <input type="text" name="" placeholder='' className='ps-5 univ-input' id="" />
                                    <span className='colorLabel'></span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Background Color</label>
                                <div className="position-relative">
                                    <input type="text" name="" placeholder='' className='ps-5 univ-input' id="" />
                                    <span className='colorLabel'></span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Header Color</label>
                                <div className="position-relative">
                                    <input type="text" name="" placeholder='' className='ps-5 univ-input' id="" />
                                    <span className='colorLabel'></span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Header Text Color</label>
                                <div className="position-relative">
                                    <input type="text" name="" placeholder='' className='ps-5 univ-input' id="" />
                                    <span className='colorLabel'></span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Footer Color</label>
                                <div className="position-relative">
                                    <input type="text" name="" placeholder='' className='ps-5 univ-input' id="" />
                                    <span className='colorLabel'></span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Footer Text Color</label>
                                <div className="position-relative">
                                    <input type="text" name="" placeholder='' className='ps-5 univ-input' id="" />
                                    <span className='colorLabel'></span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Primary Button Color</label>
                                <div className="position-relative">
                                    <input type="text" name="" placeholder='' className='ps-5 univ-input' id="" />
                                    <span className='colorLabel'></span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Secondary Button Color</label>
                                <div className="position-relative">
                                    <input type="text" name="" placeholder='' className='ps-5 univ-input' id="" />
                                    <span className='colorLabel'></span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Body Color</label>
                                <div className="position-relative">
                                    <input type="text" name="" placeholder='' className='ps-5 univ-input' id="" />
                                    <span className='colorLabel'></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">

                        </div>
                    </div>
                    {/* <div className="form-group row mt-3">
                        <div className="col-sm-6 mb-3">
                            <button className='univ-btn-border w-100'>Cancel</button>
                        </div>
                        <div className="col-sm-6 mb-3">
                            <button className='univ-btn w-100'>Edit Details</button>
                        </div>
                    </div> */}
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
}
export default AddSiteConfig;