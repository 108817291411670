import { IoSearchOutline } from "react-icons/io5";
function CredentialFilter() {
    return (
        <>
            <div className="tableFilter p-3">
                <div className="searchWrap align-items-center">
                    <div className="searchField position-relative">
                        <input type="text" name="" id="" placeholder="Enter Keyword" />
                        <button className="px-4">Search</button>
                        <IoSearchOutline />
                    </div>
                </div>
            </div>
        </>
    )
}
export default CredentialFilter;