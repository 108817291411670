import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Issues from "../pages/Issues/Issues";
import IssuesDetail from "../pages/Issues/IssuesDetail";
import Category from "../pages/Category/Category";
import Users from "../pages/User/Users";
import TicketSubmitters from "../pages/TicketSubmitters/TicketSubmitters";
import ManageTenents from "../pages/ManageTenents/ManageTenents";
import KnowledgeBase from "../pages/KnowledgeBase/KnowledgeBase";
import TrainChatbot from "../pages/TrainChatbot/TrainChatbot";
import TicketList from "../pages/TicketSubmitters/TicketList";
import TicketListDetail from "../pages/TicketSubmitters/TicketListDetail";
import DatabaseCredential from "../pages/ManageTenents/DatabaseCredential";
import SiteConfig from "../pages/ManageTenents/SiteConfig";

export default function routes() {
  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/all-issues" element={<Issues />} />
          <Route path="/issues-details/:id" element={<IssuesDetail />} />
          <Route path="/category" element={<Category />} />
          <Route path="/users" element={<Users />} />
          <Route path="/ticket-submitters" element={<TicketSubmitters />} />
          <Route path="/ticket-lists" element={<TicketList />} />
          <Route path="/ticket-lists-detail" element={<TicketListDetail />} />
          <Route path="/manage-tenents" element={<ManageTenents />} />
          <Route path="/knowledge-base" element={<KnowledgeBase />} />
          <Route path="/train-chatbot" element={<TrainChatbot />} />
          <Route path="/database-credential" element={<DatabaseCredential />} />
          <Route path="/site-config" element={<SiteConfig />} />
        </Routes>
      </div>
    </>
  );
}
