import { useState } from "react";
import CategoryList from "./partials/CategoryList";
import AddEditCategory from "./modals/AddEditCategory";
import { IoAddCircleSharp } from "react-icons/io5";

function Category() {
  const [showModal, setShowModal] = useState(false);
  const [refetch, setRefetch] = useState<() => void>(() => {});

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pageTitle">
              <h1>Category</h1>
              <button className="univ-btn" onClick={handleShow}>
                <IoAddCircleSharp /> Add Category
              </button>
            </div>
          </div>
          <div className="col-12">
            <div className="display-card mt-3 p-0">
              <CategoryList setRefetch={setRefetch} />
            </div>
          </div>
        </div>
      </div>

      <AddEditCategory
        show={showModal}
        onHide={handleClose}
        refetch={refetch}
      />
    </>
  );
}
export default Category;
