import { MdEdit, MdRemoveRedEye, MdDeleteOutline } from "react-icons/md";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  useCreateDataMutation,
  useGetAllDataQuery,
} from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import DeleteDialog from "../../../components/DeleteDialog";
import { ErrorResponse } from "../../../interface/error";
import { useEffect, useState } from "react";
import Loader from "../../../utils/Loader";
import TenentFilter from "./TenentFilter";
import EditTenents from "../Modals/EditTenants";
import { MdDeleteSweep } from "react-icons/md";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast";
import ViewTenants from "../Modals/ViewTenants";
import { FaDatabase } from "react-icons/fa";
import { HiColorSwatch } from "react-icons/hi";
import { Link } from "react-router-dom";

function TenentList({
  setRefetch,
}: {
  setRefetch: (refetchFn: () => void) => void;
}) {
  const [filters, setFilters] = useState({
    name: "",
    site_url: "",
    created_at: "",
  });

  const [selectedTenants, setSelectedTenants] = useState<number[]>([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showBulkDeleteDialog, setShowBulkDeleteDialog] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState<any>(null);
  const [tenantToEdit, setTenantToEdit] = useState<any>(null);
  const [showEditModal, setShowEditModal] = useState(false);


  // View Tenants Modal
  const [showViewModal, setShowViewModal] = useState(false);

  const handleViewShow = () => setShowViewModal(true);
  const handleViewClose = () => setShowViewModal(false);

  const {
    data: tenantsData,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllDataQuery({
    url: Endpoint.GET_TENANT,
    params: {
      ...filters,
    },
  });

  useEffect(() => {
    if (refetch) {
      setRefetch(() => refetch);
    }
  }, [refetch, setRefetch]);

  const [createData, { isLoading: isDeleting }] = useCreateDataMutation();

  const handleDelete = async (tenantIds: any[]) => {
    try {
      const res = await createData({
        url: `/tenant/delete`,
        newData: { id: tenantIds },
      });

      if (res?.error) {
        if ("data" in res.error) {
          const errorResponse = res.error.data as ErrorResponse;
          showErrorToast(errorResponse.message);
        } else {
          showErrorToast("An unexpected error occurred.");
        }
        return;
      }

      if (res?.data?.code === 200) {
        showSuccessToast(res?.data?.message);
        setShowDeleteDialog(false);
        setShowBulkDeleteDialog(false);
        setSelectedTenants([]);
        refetch();
      }
      setShowDeleteDialog(false);
      setShowBulkDeleteDialog(false);
    } catch (err: any) {
      console.log("error is ", err?.response);
    }
  };

  const handleDeleteDialogShow = (tenant: any) => {
    setTenantToDelete(tenant?.id);
    setShowDeleteDialog(true);
  };

  const handleBulkDeleteDialogShow = () => {
    setShowBulkDeleteDialog(true);
  };

  const handleDeleteDialogHide = () => {
    setShowDeleteDialog(false);
    setTenantToDelete(null);
  };

  const handleBulkDeleteDialogHide = () => {
    setShowBulkDeleteDialog(false);
  };

  const handleEditClick = (tenant: any) => {
    setTenantToEdit(tenant);
    setShowEditModal(true);
  };

  const handleFilterApply = (newFilters: any) => {
    setFilters(newFilters);
  };

  const handleSelectTenant = (tenantId: number) => {
    setSelectedTenants((prevSelected) =>
      prevSelected.includes(tenantId)
        ? prevSelected.filter((id) => id !== tenantId)
        : [...prevSelected, tenantId]
    );
  };

  const handleSelectAll = () => {
    if (selectedTenants.length === tenantsData?.data?.data?.length) {
      setSelectedTenants([]);
    } else {
      setSelectedTenants(
        tenantsData?.data?.data?.map((tenant: any) => tenant.id)
      );
    }
  };

  const handleBulkDelete = () => {
    if (selectedTenants.length > 0) {
      handleDelete(selectedTenants);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <TenentFilter onFilterApply={handleFilterApply} loading={isLoading} />

      {isFetching ? (
        <Loader />
      ) : (
        <>
          <div className="px-3">
            {selectedTenants.length > 0 && (
              <button
                className="univ-btn-border mb-2 ml-3"
                onClick={handleBulkDeleteDialogShow}
                disabled={isDeleting}
              >
                <MdDeleteSweep color="red" size={24} />
              </button>
            )}
          </div>
          <div className="table-responsive">
            <table className="table striped-bg">
              <thead>
                <tr>
                  <th>
                    <div className="checkbox-inline">
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-2"
                        type="checkbox"
                        onClick={handleSelectAll}
                      />
                      <label htmlFor="styled-checkbox-2"></label>
                    </div>
                  </th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>URL</th>
                  <th>Created On</th>
                  <th>Ticket Action</th>
                </tr>
              </thead>
              <tbody>
                {tenantsData?.data?.data?.map((tenant: any, index: number) => (
                  <tr key={tenant.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedTenants.includes(tenant.id)}
                        onChange={() => handleSelectTenant(tenant.id)}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{tenant?.name}</td>
                    <td>{tenant?.site_url}</td>
                    <td>{tenant?.created_at}</td>
                    <td>
                      <div className="tableAction">
                        <ul>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>View</Tooltip>}
                            >
                              <button className="univ-btn-border brand-color" onClick={handleViewShow}>
                                <MdRemoveRedEye />
                              </button>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <button
                              className="univ-btn"
                              onClick={() => handleEditClick(tenant)}
                            >
                              <MdEdit /> Edit
                            </button>
                          </li>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Database Credential</Tooltip>}
                            >
                              <Link to="/database-credential" className="d-block univ-btn-border brand-color">
                                <FaDatabase />
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Site Configuration</Tooltip>}
                            >
                              <Link to="/site-config" className="d-block univ-btn-border brand-color">
                                <HiColorSwatch />
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Delete</Tooltip>}
                            >
                              <button
                                className="univ-btn-border text-danger"
                                onClick={() => handleDeleteDialogShow(tenant)}
                              >
                                <MdDeleteOutline />
                              </button>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      <DeleteDialog
        show={showDeleteDialog}
        onDialogHide={handleDeleteDialogHide}
        onDelete={() => handleDelete([tenantToDelete])}
        loading={isDeleting}
        title="Delete Tenant"
        message="Are you sure you want to delete this tenant? This action cannot be undone."
      />

      <DeleteDialog
        show={showBulkDeleteDialog}
        onDialogHide={handleBulkDeleteDialogHide}
        onDelete={handleBulkDelete}
        loading={isDeleting}
        title="Bulk Delete Tenants"
        message="Are you sure you want to delete the selected tenants? This action cannot be undone."
      />

      <EditTenents
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        tenant={tenantToEdit}
        refetch={refetch}
      />

      <ViewTenants show={showViewModal} onViewHide={handleViewClose} />
    </>
  );
}

export default TenentList;
