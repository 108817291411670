import Modal from 'react-bootstrap/Modal';
import { IoClose, IoFlagOutline } from "react-icons/io5";

interface AddDBProps {
    show: boolean;
    onHide: () => void;
}
function AddDatabaseConfig({ show, onHide }: AddDBProps){
    return(
        <>
        <Modal show={show} onHide={onHide} centered size='lg' >
            <Modal.Body>
                <div className="modal-head">
                    <div>
                        <button className='univ-btn-border mb-2'><IoFlagOutline/></button>  
                        <h2>New Database Configuration</h2>
                        <p>Please enter related information for tenant.</p>
                    </div>
                    <button className='univ-btn-transparent p-0' onClick={onHide}><IoClose /></button>
                </div>
                <div>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6 mb-3">
                            <label>Connection</label>
                            <input type="text" className='univ-input' placeholder='Enter Connection' name="" id="" />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <label>Host</label>
                            <input type="text" className='univ-input' placeholder='Enter Host' name="" id="" />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <label>Port</label>
                            <input type="text" className='univ-input' placeholder='Enter Port' name="" id="" />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <label>Database Name</label>
                            <input type="text" className='univ-input' placeholder='Enter Database Name' name="" id="" />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <label>Username</label>
                            <input type="text" className='univ-input' placeholder='Enter Username' name="" id="" />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <label>Password</label>
                            <input type="password" className='univ-input' placeholder='Enter Password' name="" id="" />
                        </div>
                        
                    </div>
                    <div className="form-group row mt-3">
                        <div className="col-sm-6 mb-3">
                            <button className='univ-btn-border w-100'>Cancel</button>
                        </div>
                        <div className="col-sm-6 mb-3">
                            <button className='univ-btn w-100'>Confirm</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
}
export default AddDatabaseConfig;