import Multiselect from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
function TicketList() {
  const options = [
    { name: "Option 1", id: 1 },
    { name: "Option 2", id: 2 },
    { name: "Option 3", id: 3 },
  ];
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pageTitle">
              <h1>Ticket List</h1>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4">
            <div className="display-card ticketList position-relative p-3 mt-3">
              <Link to="/ticket-lists-detail">
                <h3>Billing Issues</h3>
                <p>Description From Submitters here</p>
                <div className="row">
                  <div className="col-xl-6 mb-3">
                    <label>Opened Date</label> <br />
                    <span>10/06/2024</span>
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label>Closed Date</label> <br />
                    <span>10/06/2024</span>
                  </div>
                </div>
              </Link>
              <div className="form-group">
                <label>Moderators</label>
                <Multiselect
                  options={options}
                  displayValue="name"
                  showCheckbox={true}
                  placeholder="Select Moderator"
                />
              </div>
              <div className="ticketStatus">
                <span></span> Resolved
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TicketList;
