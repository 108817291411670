import { MdRemoveRedEye } from "react-icons/md";
import { Tooltip, OverlayTrigger, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetAllDataQuery } from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import { useState } from "react";
import IssuesFilter from "./IssuesFilter";
import Loader from "../../../utils/Loader";
import { MdDeleteOutline } from "react-icons/md";
import sanitizeHtml from "../../../utils/SanitizeHtml";
import Pagination from "../../../components/Pagination";

function IssuesList() {
  const [filters, setFilters] = useState({
    tenant: "",
    submitter: "",
    moderator: "",
  });

  const {
    data: issue,
    isFetching,
    isLoading,
  } = useGetAllDataQuery(
    {
      url: Endpoint.GET_ISSUE,
      params: {
        ...filters,
      },
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleFilterApply = (newFilters: any) => {
    setFilters(newFilters);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <IssuesFilter onFilterApply={handleFilterApply} loading={isLoading} />
      <div className="px-3 pb-2 d-flex align-items-center">
        <p className="mb-0">3 selected</p>
        <button className="univ-btn-border text-danger ms-3 py-1"><MdDeleteOutline /></button>
      </div>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          {" "}
          <div className="table-responsive">
            <table className="table striped-bg">
              <thead>
                <tr>
                  <th>
                    <div className="checkbox-inline">
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-2"
                        type="checkbox"
                      />
                      <label htmlFor="styled-checkbox-2"></label>
                    </div>
                  </th>
                  <th>Associated Tenant</th>
                  <th>Submitter</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Moderator</th>
                  <th>Date Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {issue?.data?.data?.map((iss: any) => (
                  <tr key={iss.id}>
                    <td>
                      <div className="checkbox-inline">
                        <input
                          className="styled-checkbox"
                          id={`styled-checkbox-${iss.id}`}
                          type="checkbox"
                        />
                        <label htmlFor={`styled-checkbox-${iss.id}`}></label>
                      </div>
                    </td>
                    <td>
                      <label className="heading-color">
                        {iss?.tenant?.name}
                      </label>
                      <br />
                      <span>ID #{iss?.tenant?.id}</span>
                    </td>
                    <td>
                      <label className="heading-color">
                        {iss?.submitter?.fullname}
                      </label>
                    </td>
                    <td>
                      {sanitizeHtml(iss?.details, {
                        ALLOWED_TAGS: ["b", "i", "u"],
                      })}
                    </td>
                    <td>
                      <div className="mt-status">
                        <Dropdown drop="down-centered" className="dropdown">
                          <Dropdown.Toggle>
                            <span>{iss?.status_name || "Unknown"}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <span>Pending</span>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <span>Resolved</span>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <span>Open</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                    <td>
                      <div className="userSelector">{iss?.moderator?.name}</div>
                    </td>
                    <td>
                      <span>
                        {new Date(iss?.created_at).toLocaleDateString()}
                      </span>{" "}
                      <br />
                      <span>
                        {new Date(iss?.created_at).toLocaleTimeString()}
                      </span>
                    </td>
                    <td>
                      <div className="tableAction">
                        <ul>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>View</Tooltip>}
                            >
                              <Link
                                to={`/issues-details/${iss.id}`}
                                className="univ-btn-border brand-color"
                              >
                                <MdRemoveRedEye />
                              </Link>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      <Pagination />
    </>
  );
}
export default IssuesList;
