import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Cookies } from "react-cookie";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";

const cookies = new Cookies();

cookies.set(
  "access_token",
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5MCIsImp0aSI6IjZlZDliODAyOTZiMjU4YTllMDc3ZGExMDYxZTQ2YTRkNmYxNmJjMTE0MzE3MzhlM2FlNTNlODJhODRlYTE4ZjZlMzViOGYzZDgwOTA3ZThlIiwiaWF0IjoxNzMzNDc1MjUyLjIyNTgzNiwibmJmIjoxNzMzNDc1MjUyLjIyNTgzOSwiZXhwIjoxNzY1MDExMjUyLjIxNjc4Nywic3ViIjoiMTE2Iiwic2NvcGVzIjpbXX0.jhLm-YIFoWdt7gl5015ByFTvhnAf2j6SznXE7yGrfny0bVxsBNysZrkuxRTad3uBZm1QIN7eqL07DW6Q8J4DGEjVIfE6CyPSSrHoHJ4FtGLIZEMtLdl2Jut7GylYCbNLwgLR6B8GyW6vFyPFt9kSZENvZcYsA_QbkPUkTR7jaA6vFTHfv38McICSRA-Zay0qn6Fp9Rig7PX0RBZUCDvHN0ohbnBx6V8ouJLa-XnLVrjJKhPiMfxrDuCS0dnzRYZiouzw8Z_Fw9UvM_yJSohHNtc75QtHfrrTVkDtQ_5gSgOTnqLUdVugZSpJW0qZ2cUa2usejpHpc6cNf9Ug3zVwSBLA1IE7cbih7t-i2QrRFx6Cj-9FLh1uyhOzMHbH81LnN-8lP8g9bN4nsNtqHx28xKZir_IJPqLUpN_cSwiK-dIMmH8KmVloa4WMY3ynPLnWsmnRolkJBnOEijQOqf0RFCFZRK6g14SGeUBtfxXw2HD1UjGktMVnfu5RcSyvsRt0BOPp1vv7CmXEW-KvJMm84NuAfEm93NB7N52SudKfR2Hg0wh0KagxCyRWy2N4ZHS1lEmyx_zse0vbWdqOJXh7Tt7odFHA0sXeZAeSz75BY0uGKbNlKE5P8CEgWUGgj5wL_OBmfEs94SUZWl420WwNNQxfYCEYsz-MJg3pUbfDki4"
);

const ssoAuthorizationToken = "34343adfdafadfasdfasdfas";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}/api/v2`,
  prepareHeaders: async (headers) => {
    const cookieValuePromise = cookies.get("access_token");
    const [cookieValue] = await Promise.all([cookieValuePromise]);
    if (cookieValue) {
      headers.set("Authorization", `Bearer ${cookieValue}`);
    }
    headers.set("ssoauthorization", ssoAuthorizationToken);
    headers.set("Accept", "application/json");
    return headers;
  },
});

// const redirectToLogin = () => {
//   cookies.remove("access_token", { path: "/" });
//   window.location.href = "/sign-in";
// };

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // if (result.error && result.error.status === 401) {
  //   redirectToLogin();
  // }
  return result;
};

export const fetchApi = createApi({
  reducerPath: "fetchApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllData: builder.query({
      query: ({ url, params }) => ({
        url,
        method: "GET",
        params,
      }),
    }),
    getDataById: builder.query({
      query: (url) => ({
        url,
        method: "GET",
      }),
    }),
    deleteData: builder.mutation({
      query: (url) => ({
        url,
        method: "DELETE",
      }),
    }),
    createData: builder.mutation({
      query: ({ url, newData }) => ({
        url,
        method: "POST",
        body: newData,
      }),
    }),
    updateData: builder.mutation({
      query: ({ url, updateData }) => ({
        url,
        method: "PUT",
        body: updateData,
      }),
    }),
  }),
});

export const {
  useGetAllDataQuery,
  useGetDataByIdQuery,
  useDeleteDataMutation,
  useCreateDataMutation,
  useUpdateDataMutation,
} = fetchApi;
