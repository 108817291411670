import Modal from "react-bootstrap/Modal";
import { IoClose, IoFlagOutline } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useGetAllDataQuery,
  useUpdateDataMutation,
} from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import Loader from "../../../utils/Loader";
import handleErrors from "../../../utils/ApiError";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast";

interface EditUserProps {
  show: boolean;
  onHide: () => void;
  user: any;
  refetch: () => void;
}

function EditUser({ show, onHide, user, refetch }: EditUserProps) {
  const {
    data: tenantsData,
    isLoading,
    isFetching,
  } = useGetAllDataQuery({
    url: Endpoint.GET_TENANT,
    params: {},
  });

  const [updateData, { isLoading: isUpdating }] = useUpdateDataMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: user?.id || "",
      name: user?.name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      status: user?.status ?? "",
      role: user?.role || "",
      tenant_id: user?.tenant?.id || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: Yup.string().required("Phone number is required"),
      status: Yup.string().required("Status is required"),
      role: Yup.string().required("Role is required"),
      tenant_id: Yup.string().required("Tenant is required"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await updateData({
          url: Endpoint.UPDATE_USER,
          updateData: values,
        });
        handleErrors(res, formik.setErrors);
        if (res?.data?.code === 200) {
          showSuccessToast(res?.data?.message);
          onHide();
          refetch();
          formik.resetForm();
        }
      } catch (error) {
        showErrorToast("...error");
      }
    },
  });

  if (isFetching) return <Loader />;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="modal-head">
          <div>
            <button className="univ-btn-border mb-2">
              <IoFlagOutline />
            </button>
            <h2>Edit User</h2>
            <p>Please enter a name for user.</p>
          </div>
          <button className="univ-btn-transparent p-0" onClick={onHide}>
            <IoClose />
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="form-group mb-3">
            <label>User (Full Name)</label>
            <input
              type="text"
              className="univ-input"
              placeholder="e.g John Doe"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="error">
                {typeof formik.errors.name === "string"
                  ? formik.errors.name
                  : null}
              </div>
            )}
          </div>

          <div className="row form-group">
            <div className="col-md-6 mb-3">
              <label>Email</label>
              <input
                type="text"
                className="univ-input"
                placeholder="Email Address"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="error">
                  {typeof formik.errors.email === "string"
                    ? formik.errors.email
                    : null}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label>Phone Number</label>
              <input
                type="text"
                className="univ-input"
                placeholder="Phone Number"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className="error">
                  {typeof formik.errors.phone === "string"
                    ? formik.errors.phone
                    : null}
                </div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label>Associated Tenant</label>
            <select
              name="tenant_id"
              className="univ-input"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tenant_id}
            >
              <option value="">Select Tenant</option>
              {tenantsData?.data?.data?.map((tenant: any) => (
                <option key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </option>
              ))}
            </select>
            {formik.touched.tenant_id && formik.errors.tenant_id && (
              <div className="error">
                {typeof formik.errors.tenant_id === "string"
                  ? formik.errors.tenant_id
                  : null}
              </div>
            )}
          </div>

          <div className="row form-group">
            <div className="col-md-6 mb-3">
              <label>Status</label>
              <select
                name="status"
                className="univ-input"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={String(formik.values.status)}
              >
                <option value="">Set Status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              {formik.touched.status && formik.errors.status && (
                <div className="error">
                  {typeof formik.errors.status === "string"
                    ? formik.errors.status
                    : null}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label>Role</label>
              <select
                name="role"
                className="univ-input"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
              >
                <option value="">Select Role</option>
                <option value="superadmin">SuperAdmin</option>
                <option value="admin">Admin</option>
                <option value="moderator">Moderator</option>
              </select>
              {formik.touched.role && formik.errors.role && (
                <div className="error">
                  {typeof formik.errors.role === "string"
                    ? formik.errors.role
                    : null}
                </div>
              )}
            </div>
          </div>

          <div className="form-group row mt-3">
            <div className="col-sm-6 mb-3">
              <button
                type="button"
                className="univ-btn-border w-100"
                onClick={onHide}
              >
                Cancel
              </button>
            </div>
            <div className="col-sm-6 mb-3">
              <button
                type="submit"
                className="univ-btn w-100"
                disabled={isLoading || isUpdating}
              >
                {isUpdating ? "Updating...." : "Update"}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditUser;
