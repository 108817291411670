import { useState } from 'react';
import { LuMenu } from "react-icons/lu";
import {Offcanvas} from 'react-bootstrap';
import { GoPlus } from "react-icons/go";
import UserImage from '../assets/images/user.png'
import Logo from '../assets/images/logo.svg'

type ToggleProps = {
    toggleSidebar: () => void;
};
function Header({ toggleSidebar }: ToggleProps){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <>
        <header>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-3">
                       <div className="d-flex align-items-center">
                       <div className="hamIcon pe-cursor me-2" onClick={toggleSidebar}>
                            <LuMenu />
                        </div>
                        <div className="mobileLogo">
                            <img src={Logo} alt="" />
                        </div>
                       </div>
                    </div>

                    <div className="col-9">
                        <div className="headerActions text-end">
                            <div className="headerDrop pe-cursor" onClick={handleShow}>
                                <div className="headerUser justify-content-end">
                                    <img src={UserImage} alt="User" />
                                    <div className="text-start">
                                        <h3>Wikket Sunny</h3>
                                        <p>Super Admin</p>
                                    </div>
                                </div>
                            </div>

                            <Offcanvas show={show} onHide={handleClose} placement="end">
                                <Offcanvas.Header closeButton className='align-items-start pb-0 mb-0'>
                                    <div>
                                <Offcanvas.Title>Current Profile</Offcanvas.Title>
                                <p>These are your profiles for logging into multiple tenants. You can hold upto 25 profiles</p>
                                </div>
                                </Offcanvas.Header>
                                <Offcanvas.Body className='pt-0'>
                                    <div className="profileDetail">
                                        <div className="activeProfile">
                                            <div className='d-flex align-items-center'>
                                                <span></span>
                                                <img src={UserImage} alt="User" />
                                                <h3>John Adams</h3>
                                            </div>
                                            <label>Super Admin</label>
                                        </div>
                                        <hr />
                                        <div className="tenantList">
                                            <label>Tenants</label>
                                            <ul>
                                                <li>
                                                    <img src={UserImage} alt="" />
                                                    <div>
                                                        <h4>Life Max 50</h4>
                                                        <span>Domain : <span className='brand-color'>www.domain.com/url</span></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <img src={UserImage} alt="" />
                                                    <div>
                                                        <h4>Life Max 50</h4>
                                                        <span>Domain : <span className='brand-color'>www.domain.com/url</span></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <img src={UserImage} alt="" />
                                                    <div>
                                                        <h4>Life Max 50</h4>
                                                        <span>Domain : <span className='brand-color'>www.domain.com/url</span></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <button className='univ-btn-transparent'><GoPlus /> Add New Tenant</button>
                                                </li>
                                            </ul>
                                        </div>
                                        <button className='univ-btn-border w-100'>Log Out</button>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                            {/* <Dropdown className="headerDrop">
                                <Dropdown.Toggle id="dropdown-basic">
                                <div className="headerUser">
                                    <img src={UserImage} alt="User" />
                                    <div>
                                            <h3>Wikket Sunny</h3>
                                            <p>sunny@domain001.com</p>
                                        </div>
                                </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="userHead-menu">
                                        <Link to="">Your profile</Link>
                                        <Link to="">Settings</Link>
                                        <Link to="">Sign out</Link>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
    )
}
export default Header;