import { MdEdit, MdDeleteOutline } from "react-icons/md";
import { Tooltip, OverlayTrigger, Dropdown } from 'react-bootstrap';
function SiteConfigList(){
    return(
        <>
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Tenant Name</th>
                        <th>Primary Color</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Tenant Name 001</td>
                        <td>
                            <span className="primaryColor"></span>
                        </td>
                        <td>
                            <div className="mt-status">
                                <Dropdown
                                    drop="down-centered"
                                    className="dropdown"
                                >
                                    <Dropdown.Toggle>
                                    <span>
                                        Active
                                    </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                        >
                                            <span>
                                            Active
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                        >
                                            <span>
                                            Inactive
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </td>
                        <td>
                            <div className="tableAction">
                                <ul>
                                    <li>
                                        <button
                                        className="univ-btn"
                                        >
                                        <MdEdit /> Edit
                                        </button>
                                    </li>
                                    <li>
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Delete</Tooltip>}
                                        >
                                        <button
                                            className="univ-btn-border text-danger"
                                        >
                                            <MdDeleteOutline />
                                        </button>
                                        </OverlayTrigger>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Tenant Name 001</td>
                        <td>
                            <span className="primaryColor"></span>
                        </td>
                        <td>
                            <div className="mt-status">
                                <Dropdown
                                    drop="down-centered"
                                    className="dropdown"
                                >
                                    <Dropdown.Toggle>
                                    <span>
                                        Active
                                    </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                        >
                                            <span>
                                            Active
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                        >
                                            <span>
                                            Inactive
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </td>
                        <td>
                            <div className="tableAction">
                                <ul>
                                    <li>
                                        <button
                                        className="univ-btn"
                                        >
                                        <MdEdit /> Edit
                                        </button>
                                    </li>
                                    <li>
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Delete</Tooltip>}
                                        >
                                        <button
                                            className="univ-btn-border text-danger"
                                        >
                                            <MdDeleteOutline />
                                        </button>
                                        </OverlayTrigger>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
    )
}
export default SiteConfigList;