import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { BsFilterLeft } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
function ChatSessionsFilter() {
  const [showFilter, setShowFilter] = useState(false);

  const showFilterHandler = () => {
    setShowFilter(true);
  };

  const hideFilterHandler = () => {
    setShowFilter(false);
  };

  return (
    <>
      <div className="tableFilter p-3">
        <div className="searchWrap align-items-center">
          <div className="searchField position-relative">
            <input type="text" name="" id="" placeholder="Enter Keyword" />
            <button className="px-4">Search</button>
            <IoSearchOutline />
          </div>
          <button
            className="univ-btn-secondary ms-2"
            onClick={showFilterHandler}
          >
            <BsFilterLeft /> Filter
          </button>
        </div>
        {showFilter && (
          <>
            <div className="row mt-4">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <select name="" className="univ-input" id="">
                  <option value="">Select Associated Tenant</option>
                  <option value="">Tenant 1</option>
                  <option value="">Tenant 2</option>
                  <option value="">Tenant 3</option>
                </select>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <select name="" className="univ-input" id="">
                  <option value="">Category Name</option>
                  <option value="">Name 1</option>
                  <option value="">Name 2</option>
                  <option value="">Name 3</option>
                </select>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <select name="" className="univ-input" id="">
                  <option value="">Category Status</option>
                  <option value="">Active</option>
                  <option value="">Inactive</option>
                </select>
              </div>
              <div className="col-12">
                <div className="searchWrap justify-content-end">
                  <button className="univ-btn-transparent text-danger me-3">
                    <MdDeleteOutline /> Clear Filter Data
                  </button>
                  <div className="d-flex">
                    <button
                      className="univ-btn-transparent"
                      onClick={hideFilterHandler}
                    >
                      Cancel
                    </button>
                    <button className="univ-btn me-3">Apply Filter</button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        )}
      </div>
    </>
  );
}
export default ChatSessionsFilter;
