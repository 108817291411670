import { useEffect, useState } from "react";
import { Tooltip, OverlayTrigger, Dropdown } from "react-bootstrap";
import { MdEdit, MdDeleteOutline, MdDeleteSweep } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import ViewQuesModal from "../modal/ViewQuesModal";
import DeleteDialog from "../../../components/DeleteDialog";
import {
  useGetAllDataQuery,
  useCreateDataMutation,
} from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import Loader from "../../../utils/Loader";
import EditQuesModal from "../modal/EditQuestionModal";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast";

function ChatFlow({
  setRefetch,
}: {
  setRefetch: (refetchFn: () => void) => void;
}) {
  const [showViewQuesModal, setShowViewQuesModal] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState<number[]>([]);
  const [questionToDelete, setQuestionToDelete] = useState<number | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showBulkDeleteDialog, setShowBulkDeleteDialog] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<any>(null);

  const {
    data: questionData,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllDataQuery({
    url: Endpoint.GET_QUESTION_ANSWER,
  });

  useEffect(() => {
    if (refetch) {
      setRefetch(() => refetch);
    }
  }, [refetch, setRefetch]);

  const [createData, { isLoading: isDeleting }] = useCreateDataMutation();

  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);

  const handleViewQuesShow = (question: any) => {
    setSelectedQuestion(question);
    setShowViewQuesModal(true);
  };

  const handleViewQuesClose = () => setShowViewQuesModal(false);

  const handleDelete = async (questionIds: number[]) => {
    try {
      const res = await createData({
        url: Endpoint.DELETE_QUESTION_ANSWER,
        newData: { id: questionIds },
      });

      if (res?.error) {
        showErrorToast("Failed to delete questions.");
        return;
      }

      if (res?.data?.code === 200) {
        showSuccessToast("Questions deleted successfully.");
        setSelectedQuestions([]);
        refetch();
      }
      setShowDeleteDialog(false);
      setShowBulkDeleteDialog(false);
    } catch (error) {
      console.error("Error deleting questions:", error);
    }
  };

  const handleDeleteDialogShow = (questionId: number) => {
    setQuestionToDelete(questionId);
    setShowDeleteDialog(true);
  };

  const handleBulkDeleteDialogShow = () => {
    setShowBulkDeleteDialog(true);
  };

  const handleDeleteDialogHide = () => {
    setShowDeleteDialog(false);
    setQuestionToDelete(null);
  };

  const handleBulkDeleteDialogHide = () => {
    setShowBulkDeleteDialog(false);
  };

  const handleSelectQuestion = (questionId: number) => {
    setSelectedQuestions((prevSelected) =>
      prevSelected.includes(questionId)
        ? prevSelected.filter((id) => id !== questionId)
        : [...prevSelected, questionId]
    );
  };

  const handleSelectAll = () => {
    if (selectedQuestions.length === questionData?.data?.data?.length) {
      setSelectedQuestions([]);
    } else {
      setSelectedQuestions(questionData?.data?.data?.map((q: any) => q.id));
    }
  };

  const handleBulkDelete = () => {
    if (selectedQuestions.length > 0) {
      handleDelete(selectedQuestions);
    }
  };

  const handleEditClick = (question: any) => {
    setQuestionToEdit(question);
    setShowEditModal(true);
  };

  if (isLoading || isFetching) return <Loader />;

  return (
    <>
      <div className="px-3">
        {selectedQuestions.length > 0 && (
          <button
            className="univ-btn-border mb-2 ml-3"
            onClick={handleBulkDeleteDialogShow}
            disabled={isDeleting}
          >
            <MdDeleteSweep color="red" size={24} />
          </button>
        )}
      </div>
      <div className="display-card p-0">
        <div className="table-responsive">
          <table className="table striped-bg">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={
                      selectedQuestions.length ===
                      questionData?.data?.data?.length
                    }
                    onChange={handleSelectAll}
                  />
                </th>
                <th>Questions/Answer</th>
                <th>Created By</th>
                <th>Last Updated</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {questionData?.data?.data?.map((q: any) => (
                <tr key={q.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedQuestions.includes(q.id)}
                      onChange={() => handleSelectQuestion(q.id)}
                    />
                  </td>
                  <td>
                    <strong>{q?.query}</strong>
                    <p>{q?.answer}</p>
                  </td>
                  <td>John Admin</td>
                  <td>{q?.updated_at}</td>
                  <td>
                    <div className="mt-status">
                      {" "}
                      <Dropdown drop="down-centered" className="dropdown">
                        <Dropdown.Toggle>
                          <span>{q.status === 1 ? "Active" : "Inactive"}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <span>Active</span>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <span>Inactive</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                  <td>
                    <div className="tableAction">
                      <ul>
                        <li>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View</Tooltip>}
                          >
                            <button
                              className="univ-btn-border brand-color"
                              onClick={() => handleViewQuesShow(q)}
                            >
                              <IoMdEye />
                            </button>
                          </OverlayTrigger>
                        </li>
                        <li>
                          <button
                            className="univ-btn"
                            onClick={() => handleEditClick(q)}
                          >
                            <MdEdit /> Edit
                          </button>
                        </li>
                        <li>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                          >
                            <button
                              className="univ-btn-border text-danger"
                              onClick={() => handleDeleteDialogShow(q.id)}
                            >
                              <MdDeleteOutline />
                            </button>
                          </OverlayTrigger>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ViewQuesModal
        show={showViewQuesModal}
        onHideView={handleViewQuesClose}
        question={selectedQuestion}
      />

      <DeleteDialog
        show={showDeleteDialog}
        onDialogHide={handleDeleteDialogHide}
        onDelete={() => handleDelete([questionToDelete!])}
        loading={isDeleting}
        title="Delete Question"
        message="Are you sure you want to delete this question? This action cannot be undone."
      />

      <DeleteDialog
        show={showBulkDeleteDialog}
        onDialogHide={handleBulkDeleteDialogHide}
        onDelete={handleBulkDelete}
        loading={isDeleting}
        title="Bulk Delete Questions"
        message="Are you sure you want to delete the selected questions? This action cannot be undone."
      />

      <EditQuesModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        question={questionToEdit}
        refetch={refetch}
      />
    </>
  );
}

export default ChatFlow;
