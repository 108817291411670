import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { BsFilterLeft } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import Endpoint from "../../../services/Endpoints";
import { useGetAllDataQuery } from "../../../services/Api";

function TenentFilter({
  onFilterApply,
  loading,
}: {
  onFilterApply: (filters: any) => void;
  loading: boolean;
}) {
  const [showFilter, setShowFilter] = useState(false);

  const { data: tenantsData } = useGetAllDataQuery({
    url: Endpoint.GET_TENANT,
  });

  const [filters, setFilters] = useState({
    name: "",
    site_url: "",
    created_at: "",
  });

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const showFilterHandler = () => {
    setShowFilter(true);
  };

  const hideFilterHandler = () => {
    setShowFilter(false);
  };

  const handleClearFilter = () => {
    setFilters({
      name: "",
      site_url: "",
      created_at: "",
    });
    onFilterApply({});
  };

  const handleApplyFilter = () => {
    onFilterApply(filters);
  };

  return (
    <>
      <div className="tableFilter p-3">
        <div className="searchWrap align-items-center">
          <div className="searchField position-relative">
            <input type="text" name="" id="" placeholder="Enter Keyword" />
            <button className="px-4">Search</button>
            <IoSearchOutline />
          </div>
          <button
            className="univ-btn-secondary ms-2"
            onClick={showFilterHandler}
          >
            <BsFilterLeft /> Filter
          </button>
        </div>
        {showFilter && (
          <>
            <div className="row mt-4">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <select
                  name="name"
                  className="univ-input"
                  id=""
                  value={filters.name}
                  onChange={handleFilterChange}
                >
                  <option value="">Tenent Name</option>
                  {tenantsData?.data?.data?.map((tenant: any) => (
                    <option key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <input
                  name="site_url"
                  className="univ-input"
                  placeholder="Site Url"
                  id=""
                  value={filters.site_url}
                  onChange={handleFilterChange}
                ></input>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <input
                  type="date"
                  className="univ-input"
                  placeholder="Created Date"
                  name="created_at"
                  id=""
                  value={filters.created_at}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-12">
                <div className="searchWrap justify-content-end">
                  <button
                    className="univ-btn-transparent text-danger me-3"
                    onClick={handleClearFilter}
                  >
                    <MdDeleteOutline /> Clear Filter Data
                  </button>
                  <div className="d-flex">
                    <button
                      className="univ-btn-transparent"
                      onClick={hideFilterHandler}
                    >
                      Cancel
                    </button>
                    <button
                      className="univ-btn me-3"
                      onClick={handleApplyFilter}
                    >
                      {loading ? "Applying...." : " Apply Filter"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        )}
      </div>
    </>
  );
}
export default TenentFilter;
