import Modal from "react-bootstrap/Modal";
import { IoClose, IoFlagOutline } from "react-icons/io5";
import DefaultAvatar from "../../../assets/images/default-avatar.svg";
import { useCreateDataMutation } from "../../../services/Api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Endpoint from "../../../services/Endpoints";
import handleErrors from "../../../utils/ApiError";
import { showSuccessToast } from "../../../utils/Toast";

interface AddEditTenetsProps {
  show: boolean;
  onHide: () => void;
  refetch?: () => void;
}
function AddEditTenents({ show, onHide, refetch }: AddEditTenetsProps) {
  const [createData, { isLoading: isCreating }] = useCreateDataMutation();

  const [logoPreview, setLogoPreview] = useState<string | undefined>(
    DefaultAvatar
  );
  const [footerLogoPreview, setFooterLogoPreview] = useState<
    string | undefined
  >(DefaultAvatar);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    site_url: Yup.string()
      .url("Invalid URL format")
      .required("Site URL is required"),
    user_info_url: Yup.string()
      .url("Invalid URL format")
      .required("User Info URL is required"),
    sso_login_url: Yup.string()
      .url("Invalid URL format")
      .required("SSO Login URL is required"),
    site_key: Yup.string().required("Site Key is required"),
    client_app_url: Yup.string()
      .url("Invalid URL format")
      .required("Client App URL is required"),
    api_url: Yup.string()
      .url("Invalid URL format")
      .required("API URL is required"),
    api_token: Yup.string().required("API Token is required"),
    message_url: Yup.string()
      .url("Invalid URL format")
      .required("Message URL is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      site_url: "",
      user_info_url: "",
      sso_login_url: "",
      site_key: "",
      client_app_url: "",
      api_url: "",
      api_token: "",
      message_url: "",
      logo: null,
      footer_logo: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          if (value !== null) {
            formData.append(key, value);
          }
        });
        const res = await createData({
          url: Endpoint.CREATE_TENANT,
          newData: formData,
        });
        handleErrors(res, formik.setErrors);
        if (res?.data?.code === 200) {
          showSuccessToast(res?.data?.message);
          if (refetch) {
            refetch();
          }
          formik.resetForm();
          onHide();
        }
      } catch (error) {
        console.error("Error creating data:", error);
      }
    },
  });

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLogoPreview(URL.createObjectURL(file));
      formik.setFieldValue("logo", file);
    }
  };

  const handleFooterLogoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setFooterLogoPreview(URL.createObjectURL(file));
      formik.setFieldValue("footer_logo", file);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered size="xl">
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-head">
              <div>
                <button className="univ-btn-border mb-2">
                  <IoFlagOutline />
                </button>
                <h2>New Tenent</h2>
                <p>Please enter related information for tenent.</p>
              </div>
              <button className="univ-btn-transparent p-0" onClick={onHide}>
                <IoClose />
              </button>
            </div>
            <div>
              <div className="form-group row mb-3">
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Name</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="Enter Name"
                    name="name"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="error">{formik.errors.name}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Email</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="Enter Email"
                    name="email"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="error">{formik.errors.email}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-3">
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Phone</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="Enter Phone"
                    name="phone"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="error">{formik.errors.phone}</div>
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Site Url</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="https://"
                    name="site_url"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.site_url}
                  />
                  {formik.touched.site_url && formik.errors.site_url && (
                    <div className="error">{formik.errors.site_url}</div>
                  )}
                </div>
              </div>
              <div className="form-group row mb-3">
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>User Info Url</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="https://"
                    name="user_info_url"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.user_info_url}
                  />
                  {formik.touched.user_info_url &&
                    formik.errors.user_info_url && (
                      <div className="error">{formik.errors.user_info_url}</div>
                    )}
                </div>
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Sso Login Url</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="https://"
                    name="sso_login_url"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.sso_login_url}
                  />
                  {formik.touched.sso_login_url &&
                    formik.errors.sso_login_url && (
                      <div className="error">{formik.errors.sso_login_url}</div>
                    )}
                </div>
              </div>
              <div className="form-group row mb-3">
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Site Key</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="Enter Site Key"
                    name="site_key"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.site_key}
                  />
                  {formik.touched.site_key && formik.errors.site_key && (
                    <div className="error">{formik.errors.site_key}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Client App Url</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="https://"
                    name="client_app_url"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.client_app_url}
                  />
                  {formik.touched.client_app_url &&
                    formik.errors.client_app_url && (
                      <div className="error">
                        {formik.errors.client_app_url}
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group row mb-3">
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Api Url</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="https://"
                    name="api_url"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.api_url}
                  />
                  {formik.touched.api_url && formik.errors.api_url && (
                    <div className="error">{formik.errors.api_url}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Api Token</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="Enter Api Token"
                    name="api_token"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.api_token}
                  />
                  {formik.touched.api_token && formik.errors.api_token && (
                    <div className="error">{formik.errors.api_token}</div>
                  )}
                </div>
              </div>
              <div className="form-group row mb-3">
                <div className="col-md-6 mb-3">
                  {" "}
                  <label>Message Url</label>
                  <input
                    type="text"
                    className="univ-input"
                    placeholder="https://"
                    name="message_url"
                    id=""
                    onChange={formik.handleChange}
                    value={formik.values.message_url}
                  />
                  {formik.touched.message_url && formik.errors.message_url && (
                    <div className="error">{formik.errors.message_url}</div>
                  )}
                </div>
              </div>
              <div className="form-group row mb-3">
                <div className="col-md-6 mb-3">
                  <label>Tenant Logo</label>
                  <div className="d-flex align-items-center">
                    <div className="logoThumb me-3">
                      <img src={logoPreview} alt="Tenant Logo" />
                    </div>
                    <div className="file-upload">
                      <label
                        htmlFor="logo"
                        className="pe-cursor univ-btn-secondary px-3 mb-0"
                      >
                        Upload Image
                      </label>
                      <input
                        id="logo"
                        type="file"
                        name="logo"
                        className="file-upload__input"
                        onChange={handleLogoChange}
                      />
                    </div>
                  </div>
                  {formik.touched.logo && formik.errors.logo && (
                    <div className="error">{formik.errors.logo}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>Footer Logo</label>
                  <div className="d-flex align-items-center">
                    <div className="logoThumb me-3">
                      <img src={footerLogoPreview} alt="Footer Logo" />
                    </div>
                    <div className="file-upload">
                      <label
                        htmlFor="footer_logo"
                        className="pe-cursor univ-btn-secondary px-3 mb-0"
                      >
                        Upload Image
                      </label>
                      <input
                        id="footer_logo"
                        type="file"
                        name="footer_logo"
                        className="file-upload__input"
                        onChange={handleFooterLogoChange}
                      />
                    </div>
                  </div>
                  {formik.touched.footer_logo && formik.errors.footer_logo && (
                    <div className="error">{formik.errors.footer_logo}</div>
                  )}
                </div>
              </div>
              <div className="form-group row mt-3">
                <div className="col-sm-6 mb-3">
                  <button
                    type="button"
                    className="univ-btn-border w-100"
                    onClick={onHide}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-sm-6 mb-3">
                  <button
                    type="submit"
                    className="univ-btn w-100"
                    disabled={isCreating}
                  >
                    {isCreating ? "Saving..." : "Confirm"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AddEditTenents;
