import TrainBanner from '../../assets/images/train-banner.png'
function TrainChatbot(){
    return(
        <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="pageTitle">
                        <h1>Train Your Chatbot</h1>
                    </div>
                </div>
                <div className="col-lg-8">
                    <img src={TrainBanner} className='img-fluid' alt="Banner" />
                </div>
            </div>
        </div>
        </>
    )
}
export default TrainChatbot;