import React, { useState } from 'react';
import Router from "./routes/routes"
import Header from './components/Header';
import SidebarMenu from './components/SidebarMenu';

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
      setIsCollapsed((prevState) => !prevState);
  };
  return (
    <>
    <div className="d-flex">
        <SidebarMenu isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
        <div className='w-100'>
          <Header toggleSidebar={toggleSidebar} />
          <div className="px-2">
            <div className="pageWrap">
              <Router/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;