import { Tooltip, OverlayTrigger, Dropdown } from "react-bootstrap";
import { MdEdit, MdDeleteOutline } from "react-icons/md";
import { useEffect, useState } from "react";
import {
  useGetAllDataQuery,
  useDeleteDataMutation,
} from "../../../services/Api";
import Loader from "../../../utils/Loader";
import DeleteDialog from "../../../components/DeleteDialog";
import Endpoint from "../../../services/Endpoints";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast";
import { ErrorResponse } from "../../../interface/error";
import UserFilter from "./UserFilter";
import EditUser from "../modal/EditUser";

function UserList({
  setRefetch,
}: {
  setRefetch: (refetchFn: () => void) => void;
}) {
  const [filters, setFilters] = useState({
    name: "",
    domain: "",
    status: "",
    phone: "",
    email: "",
    role: "",
  });

  const {
    data: users,
    refetch,
    isFetching,
    isLoading,
  } = useGetAllDataQuery(
    {
      url: Endpoint.GET_USER,
      params: {
        ...filters,
      },
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (refetch) {
      setRefetch(() => refetch);
    }
  }, [refetch, setRefetch]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState<any>(null);

  const [deleteData, { isLoading: isDeleting }] = useDeleteDataMutation();

  const handleDelete = async (userId: any) => {
    try {
      const res = await deleteData(`/superadmin/users/${userId}/delete`);

      if (res?.error) {
        if ("data" in res.error) {
          const errorResponse = res.error.data as ErrorResponse;
          showErrorToast(errorResponse.message);
        } else {
          showErrorToast("An unexpected error occurred.");
        }
        return;
      }

      if (res?.data?.code === 200) {
        showSuccessToast(res?.data?.message);
        setShowDeleteDialog(false);
        refetch();
      }
    } catch (err: any) {
      console.log("error is ", err?.response);
    }
  };

  const handleDeleteDialogShow = (user: any) => {
    setUserToDelete(user);
    setShowDeleteDialog(true);
  };

  const handleDeleteDialogHide = () => {
    setShowDeleteDialog(false);
    setUserToDelete(null);
  };

  const handleEditClick = (user: any) => {
    setUserToEdit(user);
    setShowEditModal(true);
  };

  const handleFilterApply = (newFilters: any) => {
    setFilters(newFilters);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <UserFilter onFilterApply={handleFilterApply} loading={isLoading} />
      <>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            {users?.data?.data.length > 0 ? (
              <>
                {" "}
                <div className="table-responsive mb-2">
                  <table className="table striped-bg">
                    <thead>
                      <tr>
                        <th>
                          <div className="checkbox-inline">
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-2"
                              type="checkbox"
                            />
                            <label htmlFor="styled-checkbox-2"></label>
                          </div>
                        </th>
                        <th>User</th>
                        <th>Domains</th>
                        <th>Status</th>
                        <th>Phone/Email</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.data?.data?.map((user: any) => (
                        <tr key={user.id}>
                          <td>
                            <div className="checkbox-inline">
                              <input
                                className="styled-checkbox"
                                id={`styled-checkbox-${user.id}`}
                                type="checkbox"
                              />
                              <label
                                htmlFor={`styled-checkbox-${user.id}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            <label className="heading-color">{user.name}</label>
                          </td>
                          <td>
                            <span className="brand-color">
                              {user?.domains?.length || 0} Domains
                            </span>
                          </td>
                          <td>
                            <div className="mt-status">
                              <Dropdown
                                drop="down-centered"
                                className="dropdown"
                              >
                                <Dropdown.Toggle>
                                  <span>
                                    {user.status === 1 ? "Active" : "Inactive"}
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <span>Active</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <span>Inactive</span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                          <td>
                            <span>{user?.phone}</span> <br />
                            <span>{user?.email}</span>
                          </td>
                          <td>
                            <div className="userSelector">
                              <Dropdown
                                drop="down-centered"
                                className="dropdown"
                              >
                                <Dropdown.Toggle>
                                  {user.role || "Select Role"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <span>Admin</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <span>Moderator</span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                          <td>
                            <div className="tableAction">
                              <ul>
                                <li>
                                  <button
                                    className="univ-btn"
                                    onClick={() => handleEditClick(user)}
                                  >
                                    <MdEdit /> Edit
                                  </button>
                                </li>
                                <li>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Delete</Tooltip>}
                                  >
                                    <button
                                      className="univ-btn-border text-danger"
                                      onClick={() =>
                                        handleDeleteDialogShow(user)
                                      }
                                    >
                                      <MdDeleteOutline />
                                    </button>
                                  </OverlayTrigger>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                {" "}
                <span className="d-block text-center">No results found</span>
              </>
            )}
          </>
        )}
      </>

      <DeleteDialog
        show={showDeleteDialog}
        onDialogHide={handleDeleteDialogHide}
        onDelete={() => handleDelete(userToDelete?.id)}
        loading={isDeleting}
        title="Delete User"
        message="Are you sure you want to delete this user? This action cannot be undone."
      />
      <EditUser
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        user={userToEdit}
        refetch={refetch}
      />
    </>
  );
}

export default UserList;
