import Modal from "react-bootstrap/Modal";
import { IoClose, IoFlagOutline } from "react-icons/io5";

interface UploadFileProps {
  show: boolean;
  onHideUpload: () => void;
}
function UploadFile({ show, onHideUpload }: UploadFileProps) {
  return (
    <>
      <Modal show={show} onHide={onHideUpload} centered>
        <Modal.Body>
          <div className="modal-head">
            <div className="d-flex">
              <button className="univ-btn-border mb-2 me-3">
                <IoFlagOutline />
              </button>
              <div>
                <h2>Upload File</h2>
                <p>
                  Please{" "}
                  <span className="brand-color">Download a template</span>{" "}
                  before uploading a file
                </p>
              </div>
            </div>
            <button className="univ-btn-transparent p-0" onClick={onHideUpload}>
              <IoClose />
            </button>
          </div>
          <div className="qna-wrap uploadFile my-3 py-5">
            <div className="text-center">
              <label
                htmlFor="fileUpload"
                className="me-1 mb-0 pe-cursor brand-color"
              >
                Click to upload
              </label>{" "}
              or drag and drop.
              <input type="file" className="d-none" name="" id="fileUpload" />
              <p>PDF,DOCX or CSV (max 10MB)</p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-6 mt-3">
              <button className="univ-btn-border w-100">Cancel</button>
            </div>
            <div className="col-sm-6 mt-3">
              <button className="univ-btn w-100">Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default UploadFile;
