function KnowledgeOverview() {
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
          <div className="display-card p-3 kb-overview">
            <p>Total Bot Users</p>
            <h3>50</h3>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
          <div className="display-card p-3 kb-overview">
            <p>Total Bot Sessions</p>
            <h3>25</h3>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
          <div className="display-card p-3 kb-overview">
            <p>Total Auto Resolved</p>
            <h3>4</h3>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
          <div className="display-card p-3 kb-overview">
            <p>Agent Hours Saved</p>
            <h3>
              00.00 <span>hrs</span>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
export default KnowledgeOverview;
