import Modal from "react-bootstrap/Modal";
import { IoClose, IoFlagOutline } from "react-icons/io5";
import { useCreateDataMutation } from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import { useFormik } from "formik";
import * as Yup from "yup";
import handleErrors from "../../../utils/ApiError";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast";

interface AddQuesProps {
  show: boolean;
  onHide: () => void;
  refetch: () => void;
}

function AddQuesModal({ show, onHide, refetch }: AddQuesProps) {
  const [createData, { isLoading: isCreating }] = useCreateDataMutation();

  const formik = useFormik({
    initialValues: {
      query: "",
      answer: "",
      status: "",
    },
    validationSchema: Yup.object({
      query: Yup.string().required("Question is required"),
      answer: Yup.string().required("Answer is required"),
      status: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      try {
        const payload = {
          query: values.query,
          answer: values.answer,
          status: parseInt(values.status),
        };

        const res = await createData({
          url: Endpoint.CREATE_QUESTION_ANSWER,
          newData: payload,
        });
        handleErrors(res, formik.setErrors);
        if (res?.data?.code === 200) {
          showSuccessToast(res?.data?.message);
          if (refetch) {
            refetch();
          }
          formik.resetForm();
          onHide();
        }
      } catch (error) {
        showErrorToast("An error occurred while saving the question.");
      }
    },
  });

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Body>
        <div className="modal-head">
          <div className="d-flex">
            <button className="univ-btn-border mb-2 me-3">
              <IoFlagOutline />
            </button>
            <div>
              <h2>Set Questions and Answers</h2>
              <p>Setting up FAQs</p>
            </div>
          </div>
          <button className="univ-btn-transparent p-0" onClick={onHide}>
            <IoClose />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="qna-wrap my-3">
            <div className="form-group mb-3">
              <label>#1. Question</label>
              <input
                type="text"
                className="univ-input"
                placeholder="Input Question"
                name="query"
                value={formik.values.query}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.query && formik.errors.query && (
                <span className="error">{formik.errors.query}</span>
              )}
            </div>
            <div className="form-group mb-3">
              <label>Answer</label>
              <textarea
                name="answer"
                className="univ-input"
                rows={4}
                placeholder="Input Answer"
                value={formik.values.answer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.touched.answer && formik.errors.answer && (
                <span className="error">{formik.errors.answer}</span>
              )}
            </div>
            <div className="form-group mb-3">
              <label>Status</label>
              <select
                name="status"
                className="univ-input"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              {formik.touched.status && formik.errors.status && (
                <span className="error">{formik.errors.status}</span>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-6 mt-3">
              <button
                type="button"
                className="univ-btn-border w-100"
                onClick={onHide}
              >
                Cancel
              </button>
            </div>
            <div className="col-sm-6 mt-3">
              <button
                type="submit"
                className="univ-btn w-100"
                disabled={isCreating}
              >
                {isCreating ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddQuesModal;
