import { useEffect, useState } from "react";
import { MdEdit, MdDeleteOutline } from "react-icons/md";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import DeleteDialog from "../../../components/DeleteDialog";
import CategoryFilter from "./CategoryFilter";
import {
  useDeleteDataMutation,
  useGetAllDataQuery,
} from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import { ErrorResponse } from "../../../interface/error";
import Loader from "../../../utils/Loader";
import EditCategory from "../modals/EditCategory";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast";

function CategoryList({
  setRefetch,
}: {
  setRefetch: (refetchFn: () => void) => void;
}) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<any>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<any>(null);

  const [filters, setFilters] = useState({
    name: "",
    tenant: "",
  });

  const {
    data: category,
    refetch,
    isFetching,
    isLoading,
  } = useGetAllDataQuery(
    {
      url: Endpoint.GET_CATEGORY,
      params: {
        ...filters,
      },
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (refetch) {
      setRefetch(() => refetch);
    }
  }, [refetch, setRefetch]);

  const [deleteData, { isLoading: isDeleting }] = useDeleteDataMutation();

  const handleDelete = async (categoryId: any) => {
    try {
      const res = await deleteData(
        `/superadmin/categories/${categoryId}/delete`
      );

      if (res?.error) {
        if ("data" in res.error) {
          const errorResponse = res.error.data as ErrorResponse;
          showErrorToast(errorResponse.message);
        } else {
          showErrorToast("An unexpected error occurred.");
        }
        return;
      }

      if (res?.data?.code === 200) {
        showSuccessToast(res?.data?.message);
        setShowDeleteDialog(false);
        refetch();
      }
    } catch (err: any) {
      console.log("error is ", err?.response);
    }
  };

  const handleDeleteDialogShow = (cat: any) => {
    setCategoryToDelete(cat);
    setShowDeleteDialog(true);
  };

  const handleDeleteDialogHide = () => {
    setShowDeleteDialog(false);
    setCategoryToDelete(null);
  };

  const handleEditClick = (category: any) => {
    setCategoryToEdit(category);
    setShowEditModal(true);
  };

  const handleFilterApply = (newFilters: any) => {
    setFilters(newFilters);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <CategoryFilter onFilterApply={handleFilterApply} loading={isLoading} />
      <>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            {" "}
            <div className="table-responsive">
              <table className="table striped-bg">
                <thead>
                  <tr>
                    <th>
                      <div className="checkbox-inline">
                        <input
                          className="styled-checkbox"
                          id="styled-checkbox-2"
                          type="checkbox"
                        />
                        <label htmlFor="styled-checkbox-2"></label>
                      </div>
                    </th>
                    <th>Category Name</th>
                    <th>Associated Domains</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {category?.data?.map((cat: any) => (
                    <tr key={cat.id}>
                      <td>
                        <div className="checkbox-inline">
                          <input
                            className="styled-checkbox"
                            id="styled-checkbox-2"
                            type="checkbox"
                          />
                          <label htmlFor="styled-checkbox-2"></label>
                        </div>
                      </td>
                      <td>
                        <label className="heading-color">{cat.name}</label>
                      </td>
                      <td>
                        <button className="univ-btn-transparent brand-color">
                          {cat?.domains?.length || 0} Domains
                        </button>
                      </td>
                      <td>
                        <div className="tableAction">
                          <ul>
                            <li>
                              <button
                                className="univ-btn"
                                onClick={() => handleEditClick(cat)}
                              >
                                <MdEdit /> Edit
                              </button>
                            </li>

                            <li>
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <button
                                  className="univ-btn-border text-danger"
                                  onClick={() => handleDeleteDialogShow(cat)}
                                >
                                  <MdDeleteOutline />
                                </button>
                              </OverlayTrigger>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
      <DeleteDialog
        show={showDeleteDialog}
        onDialogHide={handleDeleteDialogHide}
        onDelete={() => handleDelete(categoryToDelete?.id)}
        loading={isDeleting}
        title="Delete Category"
        message="Are you sure you want to delete this category? This action cannot be undone."
      />
      <EditCategory
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        category={categoryToEdit}
        refetch={refetch}
      />
    </>
  );
}
export default CategoryList;
