import Modal from "react-bootstrap/Modal";
import { IoClose, IoFlagOutline } from "react-icons/io5";
import {
  useCreateDataMutation,
  useGetAllDataQuery,
} from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../../utils/Loader";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast";
import handleErrors from "../../../utils/ApiError";

interface AddEditCategoryProps {
  show: boolean;
  onHide: () => void;
  refetch: () => void;
}
function AddEditCategory({ show, onHide, refetch }: AddEditCategoryProps) {
  const { data: tenantsData, isFetching } = useGetAllDataQuery({
    url: Endpoint.GET_TENANT,
    params: {},
  });

  const [createData, { isLoading: isCreating }] = useCreateDataMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      tenant_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),

      tenant_id: Yup.string().required("Tenant is required"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await createData({
          url: Endpoint.CREATE_CATEGORY,
          newData: values,
        });
        handleErrors(res, formik.setErrors);
        if (res?.data?.code === 201) {
          showSuccessToast(res?.data?.message);
          refetch();
          formik.resetForm();
          onHide();
        }
      } catch (error) {
        showErrorToast("...error");
      }
    },
  });

  if (isFetching) return <Loader />;

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <div className="modal-head">
            <div>
              <button className="univ-btn-border mb-2">
                <IoFlagOutline />
              </button>
              <h2>New Category</h2>
              <p>Please enter a name for category</p>
            </div>
            <button className="univ-btn-transparent p-0" onClick={onHide}>
              <IoClose />
            </button>
          </div>
          <form action="" onSubmit={formik.handleSubmit}>
            <div>
              <div className="form-group mb-3">
                <label>Category</label>
                <input
                  type="text"
                  className="univ-input"
                  placeholder="e.g John Doe"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="error">{formik.errors.name}</div>
                )}
              </div>
              <div className="form-group mb-3">
                <label>Select Tenant</label>
                <select
                  name="tenant_id"
                  className="univ-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tenant_id}
                >
                  <option value="">Select Tenant</option>
                  {tenantsData?.data?.data?.map((tenant: any) => (
                    <option key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </option>
                  ))}
                </select>
                {formik.touched.tenant_id && formik.errors.tenant_id && (
                  <div className="error">{formik.errors.tenant_id}</div>
                )}
              </div>
              <div className="form-group row mt-3">
                <div className="col-sm-6 mb-3">
                  <button className="univ-btn-border w-100">Cancel</button>
                </div>
                <div className="col-sm-6 mb-3">
                  <button className="univ-btn w-100" type="submit">
                    {isCreating ? "Creating...." : "Confirm"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AddEditCategory;
