import { useState } from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import { LiaChevronCircleDownSolid } from "react-icons/lia";
import { Link, useParams } from "react-router-dom";
import { useGetAllDataQuery, useCreateDataMutation } from "../../services/Api";
import handleErrors from "../../utils/ApiError";
import { showErrorToast, showSuccessToast } from "../../utils/Toast";
import Endpoint from "../../services/Endpoints";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../utils/Loader";
import { ErrorResponse } from "../../interface/error";

function IssuesDetail() {
  const [activeKeys, setActiveKeys] = useState(["0", "1", "2", "3"]);
  const [selectedModerator, setSelectedModerator] = useState("");
  const { id } = useParams();

  const toggleAccordion = (key: string) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((k) => k !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  const { data: issueDetails, isLoading } = useGetAllDataQuery(
    {
      url: `issues/${id}/show`,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [createData, { isLoading: isCreating }] = useCreateDataMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      issue_id: id,
      comment: "",
    },
    validationSchema: Yup.object({
      comment: Yup.string().required(" Comment is required"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await createData({
          url: Endpoint.CREATE_COMMENT,
          newData: values,
        });

        if (res?.error) {
          if ("data" in res.error) {
            const errorResponse = res.error.data as ErrorResponse;
            showErrorToast(errorResponse.message);
          } else {
            showErrorToast("An unexpected error occurred.");
          }
          return;
        }

        handleErrors(res, formik.setErrors);
        if (res?.data?.code === 201) {
          showSuccessToast(res?.data?.message);
        }
      } catch (error) {
        showErrorToast("...error");
      }
    },
  });

  const { data: moderatorData } = useGetAllDataQuery(
    { url: `superadmin/users/${id}/moderators-option` },
    { refetchOnMountOrArgChange: true }
  );

  const [assignModerator, { isLoading: isAsigning }] = useCreateDataMutation();

  const handleAssignModerator = async () => {
    if (!selectedModerator) {
      showErrorToast("Please select a moderator.");
      return;
    }

    try {
      const response = await assignModerator({
        url: Endpoint.POST_MODERATOR,
        newData: {
          user_id: selectedModerator,
          issue_id: id,
        },
      });

      if (response?.error) {
        if ("data" in response.error) {
          const errorResponse = response.error.data as ErrorResponse;
          showErrorToast(errorResponse.message);
        } else {
          showErrorToast("An unexpected error occurred.");
        }
        return;
      }

      if (response?.data?.status === "success") {
        showSuccessToast(response?.data?.message);
      }
    } catch (error) {
      showErrorToast("Error assigning moderator.");
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="pageTitle">
                <h1>Issue Detail</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="display-card issueWrap">
                <Accordion activeKey={activeKeys}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header onClick={() => toggleAccordion("0")}>
                      <span className="brand-color me-2">
                        <LiaChevronCircleDownSolid />
                      </span>{" "}
                      Details
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="form-group row">
                        <div className="col-xl-3 mb-3">
                          <label>Category</label>
                          <p>{issueDetails?.data?.category?.name || "-"}</p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Type</label>
                          <p>{issueDetails?.data?.type || "-"}</p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Domain</label>
                          <Link to="" className="brand-color">
                            {issueDetails?.data?.from_domain || "-"}
                          </Link>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Browser</label>
                          <p>{issueDetails?.data?.browser || "-"}</p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Status</label>
                          <div className="mt-status">
                            <Dropdown drop="down-centered" className="dropdown">
                              <Dropdown.Toggle>
                                <span>Open</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <span>Pending</span>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <span>Resolved</span>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <span>Open</span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Opend Date</label>
                          <p>{issueDetails?.data?.opened_date || "-"}</p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Closed Date</label>
                          <p>{issueDetails?.data?.closed_date || "-"}</p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Last Login</label>
                          <p>{issueDetails?.data?.last_login || "-"}-</p>
                        </div>
                      </div>
                      <hr />
                      <div className="form-group row">
                        <div className="col-xl-3 mb-3">
                          <label>Moderators</label>
                          {moderatorData?.data && (
                            <select
                              className="form-select"
                              onChange={(e) =>
                                setSelectedModerator(e.target.value)
                              }
                              defaultValue={
                                issueDetails?.data?.moderator?.id || ""
                              }
                            >
                              <option value="">Select Moderator</option>
                              {moderatorData?.data?.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      </div>
                      <div className="text-end mt-2">
                        <button
                          className="univ-btn"
                          type="button"
                          onClick={handleAssignModerator}
                          disabled={isAsigning}
                        >
                          {isAsigning ? "Assigning..." : "Assign Moderator"}
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header onClick={() => toggleAccordion("1")}>
                      <span className="brand-color me-2">
                        <LiaChevronCircleDownSolid />
                      </span>{" "}
                      Comments
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row form-group">
                        <div className="col-xl-3 mb-3">
                          <label>Comment Subject</label>
                          <p>Issue</p>
                        </div>
                        <div className="col-12 mb-3">
                          <label>Description</label>
                          <p>Others</p>
                        </div>
                        <div className="col-12">
                          <div className="commentListing">
                            <ul>
                              <li>
                                <span className="textAvatar me-3">A</span>
                                <div>
                                  <h4>Justin Amber</h4>
                                  <p>
                                    My issues hasnt been solved yet <br />
                                    - Please solve its asap!
                                  </p>
                                  <span>15/10/2024 | 10:04 PM</span>
                                </div>
                              </li>
                              <li>
                                <span className="textAvatar me-3">A</span>
                                <div>
                                  <h4>Justin Amber</h4>
                                  <p>
                                    My issues hasnt been solved yet <br />
                                    - Please solve its asap!
                                  </p>
                                  <span>15/10/2024 | 10:04 PM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <form onSubmit={formik.handleSubmit}>
                        <div className="commentWrap d-flex mt-4">
                          <span className="textAvatar me-3">A</span>
                          <div className="w-100">
                            <textarea
                              name="comment"
                              className={`univ-input ${
                                formik.errors.comment && formik.touched.comment
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Type your comment here..."
                              value={formik.values.comment}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.comment &&
                              formik.touched.comment && (
                                <div className="invalid-feedback">
                                  {formik.errors.comment}
                                </div>
                              )}
                            <div className="text-end mt-2">
                              <button
                                className="univ-btn"
                                type="submit"
                                disabled={isCreating}
                              >
                                {isCreating ? "Posting..." : "Post Comment"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header onClick={() => toggleAccordion("2")}>
                      <span className="brand-color me-2">
                        <LiaChevronCircleDownSolid />
                      </span>{" "}
                      Issue Documents
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="attachmentList">
                        <ul>
                          <li>
                            <span className="textAvatar">F</span>
                            <div>
                              <h4>file_001.zip</h4>
                              <p>3.26MB</p>
                            </div>
                          </li>
                          <li>
                            <span className="textAvatar">F</span>
                            <div>
                              <h4>file_001.zip</h4>
                              <p>3.26MB</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header onClick={() => toggleAccordion("3")}>
                      <span className="brand-color me-2">
                        <LiaChevronCircleDownSolid />
                      </span>{" "}
                      Submitter Details
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="form-group row">
                        <div className="col-xl-3 mb-3">
                          <label>Submitter</label>
                          <p>
                            {issueDetails?.data?.submitter?.fullname || "-"}
                          </p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Email</label>
                          <p> {issueDetails?.data?.submitter?.email || "-"}</p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Phone</label>
                          <p> {issueDetails?.data?.submitter?.phone || "-"}</p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>User Type</label>
                          <p>
                            {" "}
                            {issueDetails?.data?.submitter?.user_type || "-"}
                          </p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>System Ids</label>
                          {issueDetails?.data?.submitter?.system_id?.map(
                            (id: any) => <p>{id}</p>
                          ) || "-"}
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Device</label>
                          <p> {issueDetails?.data?.submitter?.device || "-"}</p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Agent Ids</label>
                          <p>
                            {" "}
                            {issueDetails?.data?.submitter?.agentds || "-"}
                          </p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Group Ids</label>
                          <p>
                            {" "}
                            {issueDetails?.data?.submitter?.groupIds || "-"}
                          </p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Register</label>
                          <p>
                            {" "}
                            {issueDetails?.data?.submitter?.register || "-"}
                          </p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Registered Date</label>
                          <p>
                            {" "}
                            {issueDetails?.data?.submitter?.registerDate || "-"}
                          </p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Last Login IP</label>
                          <p>
                            {" "}
                            {issueDetails?.data?.submitter?.lastLoginIp || "-"}
                          </p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Last Login Date</label>
                          <p>
                            {" "}
                            {issueDetails?.data?.submitter?.lastLoginDate ||
                              "-"}
                          </p>
                        </div>
                        <div className="col-xl-3 mb-3">
                          <label>Mobile Application </label>
                          <p> {issueDetails?.data?.submitter?.mobile || "-"}</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default IssuesDetail;
