import Modal from "react-bootstrap/Modal";
import { IoClose, IoFlagOutline } from "react-icons/io5";
import {
  useGetAllDataQuery,
  useUpdateDataMutation,
} from "../../../services/Api";
import Endpoint from "../../../services/Endpoints";
import { useFormik } from "formik";
import * as Yup from "yup";

import Loader from "../../../utils/Loader";
import handleErrors from "../../../utils/ApiError";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast";

interface EditCategoryProps {
  show: boolean;
  onHide: () => void;
  category: any;
  refetch: () => void;
}

function EditCategory({ show, onHide, category, refetch }: EditCategoryProps) {
  const { data: tenantsData, isFetching } = useGetAllDataQuery({
    url: Endpoint.GET_TENANT,
    params: {},
  });

  const [updateData, { isLoading: isUpdating }] = useUpdateDataMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: category?.id || "",
      name: category?.name || "",
      tenant_id: category?.tenant?.id || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      tenant_id: Yup.string().required("Tenant is required"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await updateData({
          url: Endpoint.UPDATE_CATEGORY,
          updateData: values,
        });
        handleErrors(res, formik.setErrors);
        if (res?.data?.code === 200) {
          showSuccessToast(res?.data?.message);
          onHide();
          refetch();
          formik.resetForm();
        }
      } catch (error) {
        showErrorToast("Error updating category");
      }
    },
  });

  if (isFetching) return <Loader />;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="modal-head">
          <div>
            <button className="univ-btn-border mb-2">
              <IoFlagOutline />
            </button>
            <h2>Edit Category</h2>
            <p>Please enter a name for category</p>
          </div>
          <button className="univ-btn-transparent p-0" onClick={onHide}>
            <IoClose />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="form-group mb-3">
              <label>Category</label>
              <input
                type="text"
                className="univ-input"
                placeholder="e.g John Doe"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="error">
                  {typeof formik.errors.name === "string"
                    ? formik.errors.name
                    : null}
                </div>
              )}
            </div>
            <div className="form-group mb-3">
              <label>Select Tenant</label>
              <select
                name="tenant_id"
                className="univ-input"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tenant_id}
              >
                <option value="">Select Tenant</option>
                {tenantsData?.data?.data?.map((tenant: any) => (
                  <option key={tenant.id} value={tenant.id}>
                    {tenant.name}
                  </option>
                ))}
              </select>
              {formik.touched.tenant_id && formik.errors.tenant_id && (
                <div className="error">
                  {typeof formik.errors.tenant_id === "string"
                    ? formik.errors.tenant_id
                    : null}
                </div>
              )}
            </div>
            <div className="form-group row mt-3">
              <div className="col-sm-6 mb-3">
                <button
                  className="univ-btn-border w-100"
                  type="button"
                  onClick={onHide}
                >
                  Cancel
                </button>
              </div>
              <div className="col-sm-6 mb-3">
                <button className="univ-btn w-100" type="submit">
                  {isUpdating ? "Updating...." : "Update"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditCategory;
