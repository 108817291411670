import DOMPurify from "dompurify";

const sanitizeHtml = (
  html: string | undefined,
  options: DOMPurify.Config = {}
): string => {
  return DOMPurify.sanitize(html || "", options);
};

export default sanitizeHtml;
